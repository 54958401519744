import { createRouter, createWebHistory } from 'vue-router';
// Use type-only import for RouteRecordRaw
import type { RouteRecordRaw } from 'vue-router';
import UserLogin from '../components/UserLogin.vue';
import UserLogged from '../components/UserLoggedIn.vue';
import FreddyAssistant from '../components/FreddyAssistant.vue';
import UserForgotPassword from '../components/UserForgotPassword.vue';
import UserConfirmPassword from '../components/UserConfirmPassword.vue';
import UserRegistration from '../components/UserRegistration.vue';
import AccountRegistrationAccessDenied from "../components/AccountRegistrationAccessDenied.vue";
import AccountRegistrationUserNameUpdate from '@/components/AccountRegistrationUserNameUpdate.vue';
import ResetPasswordSuccesPage from '@/components/ResetPasswordSuccesPage.vue';
import ResetPasswordFailurePage from '@/components/Store/ResetPasswordFailurePage.vue';
import UserRegistrationSuccesPage from '@/components/UserRegistrationSuccesPage.vue';
import UserRegistrationFailurePage from '@/components/UserRegistrationFailurePage.vue';
import UserRegistrationEnterCodeManually from '@/components/UserRegistrationEnterCodeManually.vue';
import UserRegistrationEmailOTPInput from '@/components/UserRegistrationEmailOTPInput.vue'
import SpinnerHandler from '@/components/spinners/SpinnerHandler.vue';
import ForgotPasswordEmailCodeVerification from '@/components/ForgotPasswordEmailCodeVerification.vue';
import Test from '@/components/test.vue';
import AuthService from '@/Services/AuthService';
import TokenManager from '@/Services/TokenManager';
import UserRegistrationCompanyWelcome from '@/components/UserRegistrationCompanyWelcome.vue';
import AutomaticallyUserChoosen from '@/components/automaticallyUserChoosen.vue';
import VerificationLinkTimeout from '@/components/VerificationLinkTimeout.vue';

const routes: Array<RouteRecordRaw> = [
  { path: '/auth/login', name: 'UserLogin', component: UserLogin, meta: { requiresAuth: false } },
  { path: '/logged', name: 'UserLogged', component: UserLogged, props: true, meta: { requiresAuth: false } },
  { path: '/freddy/assistant', name: 'FreddyAssistant', component: FreddyAssistant, meta: { requiresAuth: true } },
  { path: '/', redirect: { name: 'FreddyAssistant' } },
  { path: '/forgotpassword', name: 'UserForgotPassword', component: UserForgotPassword, meta: { requiresAuth: false } },
  { path: '/confirm-password', name: 'UserConfirmPassword', component: UserConfirmPassword, meta: { requiresAuth: false } },
  { path: '/user/register', name: 'UserRegistration', props: (route) => ({ emailKey: route.query.emailKey }), component: UserRegistration, meta: { requiresAuth: false } },
  { path: '/user/enter-code', props: (route) => ({ emailKey: route.query.emailKey }), name: 'UserRegistrationEnterCodeManually', component: UserRegistrationEnterCodeManually, meta: { requiresAuth: false } },
  { path: '/token/verify', name: 'UserRegistrationEmailOTPInput', props: (route) => ({ emailKey: route.query.emailKey }), component: UserRegistrationEmailOTPInput, meta: { requiresAuth: false } },
  // { path: '/:emailKey/token/verify', name: 'UserRegistrationEmailOTP', component: UserRegistrationEmailOTPInput ,meta: { requiresAuth: false } },
  { path: '/auth/reset', name: 'UserForgotPassword', component: UserForgotPassword, meta: { requiresAuth: false } },
  { path: '/auth/resetpassword', name: 'UserResetPasswordConfirm', component: UserConfirmPassword, meta: { requiresAuth: false } },
  { path: '/registrationaccessdenied', name: 'AccountRegistrationAccessDenied', component: AccountRegistrationAccessDenied, meta: { requiresAuth: false } },
  { path: '/updateusername', name: 'AccountRegistrationUserNameUpdate', component: AccountRegistrationUserNameUpdate, meta: { requiresAuth: false } },
  //  { path: '/checkemail', name: 'ForgotPasswordEmailCodeVerification', component: ForgotPasswordEmailCodeVerification,meta: { requiresAuth: false } },
  { path: '/registration/status/success', name: 'UserRegistrationSuccesPage', component: UserRegistrationSuccesPage, meta: { requiresAuth: false } },
  { path: '/registration/status/failed', name: 'UserRegistrationFailurePage', component: UserRegistrationFailurePage, meta: { requiresAuth: false } },
  { path: '/resetpassword/status/success', name: 'ResetPasswordSuccesPage', component: ResetPasswordSuccesPage, meta: { requiresAuth: false } },
  { path: '/resetpassword/status/falied', name: 'ResetPasswordFailurePage', component: ResetPasswordFailurePage, meta: { requiresAuth: false } },
  { path: '/loading', name: 'SpinnerHandler', component: SpinnerHandler, meta: { requiresAuth: false } },
  { path: '/test', name: 'Test', component: Test, meta: { requiresAuth: false } },
  { path: '/timeout', name: 'timeout', component: VerificationLinkTimeout, meta: { requiresAuth: false } },
  { path: '/checkemail', name: 'ForgotPasswordEmailCodeVerification', component: ForgotPasswordEmailCodeVerification, meta: { requiresAuth: false } },
  { path: '/registration/success', name: 'UserRegistrationSuccesPage', component: UserRegistrationSuccesPage, meta: { requiresAuth: false } },
  { path: '/registration/falied', name: '', component: UserRegistrationFailurePage, meta: { requiresAuth: false } },
  { path: '/registration/welcome', name: 'UserRegistrationCompanyWelcome', component: AutomaticallyUserChoosen, meta: { requiresAuth: false } }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const tokenManager = TokenManager.getInstance();
  const authService = new AuthService();

  // Check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log(to.meta)
    const token = authService.GetCookieValue('freddyUserId');
    const tokenExpiration = tokenManager.getTokenExpiration();

    // If token doesn't exist or has expired, refresh it or redirect to login
    if (!token || (tokenExpiration && Date.now() > tokenExpiration.getTime())) {
      try {
        // Attempt to refresh token
        const refreshedToken = await tokenManager.refreshToken();

        if (refreshedToken) {
          next(); // Token refreshed, continue navigation
        } else {
          // Token refresh failed, clear cookies and redirect to login
          tokenManager.clearCookies();
          next({
            path: '/auth/login',
            query: { returnUrl: to.fullPath }, // Optionally redirect back to the attempted route after login
          });
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        next({
          path: '/auth/login',
          query: { returnUrl: to.fullPath },
        });
      }
    } else {
      // Token exists and is valid
      next();
    }
  } else {
    // If the route doesn't require authentication, proceed normally
    next();
  }
});

export default router;
