<template>
  <div
    class="dashboard p-3 text-back h-full"
    :style="{ display: contentDisplay }"
  >
    <div
      class="dashboard-body flex flex-col justify-between relative h-[calc(100%-70px)]"
    >
      <Transition>
        <CheckPoints
          v-if="isSelectCheckpoints"
          @onCheckPointDashBoardClosed="checkPointDashBoardClosed"
          @onTicketSelected="onTicketSelected"
          :approveOrDeclineTrigger="approveOrDeclineTrigger"
        />
      </Transition>
      <Transition>
        <OrganizationDashBoard
          v-if="
            !isOrganizationAssistantsDashBoardOpened && !isSelectCheckpoints
          "
          :isCollapseView="isSettingsOpened"
          :isLoading="isUserConfigLoading"
          @onOrganizationSelect="onOrganizationSelect"
          @selectCheckpoints="selectCheckpoints"
          @organizationName="currentOrganizationName"
        />
        <AssistantDashBoard
          v-else-if="
            isOrganizationAssistantsDashBoardOpened && !isSelectCheckpoints
          "
          @onAssistantsDashBoardClosed="onAssistantsDashBoardClose"
          @emptyThreadList="emptyThreadList"
          @assistantSelected="onAssistantSelected"
        />
      </Transition>
      <Transition>
        <div
          v-if="isSettingsOpened"
          class="dashboard-body-overlay absolute top-0 right-0 w-full h-full delay-500 bg-backgroundSidebar"
        >
          <SettingsMenu
            @closeSettings="closeSettings"
            @settingsItemSelected="settingsItemSelected"
          />
        </div>
      </Transition>
    </div>

    <div class="dashboard-footer absolute bottom-0 left-0 right-0">
      <UserProfile
        :isSettingsOpened="isSettingsOpened"
        :profileOrganizationName="profileOrganizationName"
        @openSettings="openSettings"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, onMounted, computed, defineProps, watch } from "vue";
import UserProfile from "./UserProfile.vue";
import OrganizationDashBoard from "./OrganizationDashboard/OrganizationDashBoard.vue";
import AssistantDashBoard from "./AssistantDashBoard/AssistantDashBoard.vue";
import SettingsMenu from "../SettingsPages/SettingsMenu.vue";
import { useStore } from "vuex";
import { UserService } from "@/Services/UserService";
import type { IUserConfiguration } from "@/Models/Response/User/IUserConfiguration";
import AppConfigGlobal from "../../core/config/uiSettings";
import type { ISettingsMenuItem } from "@/Models/Common/ISettingsMenuItem";
import CheckPoints from "./CheckPoints/CheckPointDashBoard.vue";
import AuthService from "@/Services/AuthService";

interface State {
  contentDisplay: string;
  yDivWidth: string;
  yComponentStyles: any;
}
const orgId = ref(2);
const store = useStore<State>();
const profileOrganizationName = ref("");
const contentDisplay = computed(() => store?.state.contentDisplay);
const emits = defineEmits([
  "SettingsMenuClosed",
  "settingsMenuOpened",
  "settingsItemSelected",
  "assistantDashBoardOpened",
  "assistantDashBoardClosed",
  "emptyThreadList",
  "onSelectCheckpoints",
  "onDeSelectCheckpoints",
  "onTicketSelected",
  "assistantSelected",
  "organizationSelected",
]);
const props = defineProps({
  isPreDefinedQuery: {
    type: Boolean,
    default: false,
    required: false,
  },
  approveOrDeclineTrigger: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const userService = new UserService();
const authService = new AuthService();
const userConfiguration = ref<IUserConfiguration>({} as IUserConfiguration);
const isSettingsOpened = ref(false);
const isOrganizationAssistantsDashBoardOpened = ref(false);
const isUserConfigLoading = ref<boolean>(false);
const isSelectCheckpoints = ref<boolean>(false);

const emptyThreadList = () => {
  emits("emptyThreadList");
};

const currentOrganizationName = (value: any) => {
  authService.CreateCookie("CurrentOrgName", value);
  profileOrganizationName.value = value;
  emits("organizationSelected", value);
};

const openSettings = () => {
  isSettingsOpened.value = true;
  emits("settingsMenuOpened");
};
const closeSettings = () => {
  isSettingsOpened.value = false;
  emits("SettingsMenuClosed");
};

const settingsItemSelected = (item: ISettingsMenuItem) => {
  emits("settingsItemSelected", item);
};

const onOrganizationSelect = (organization: any) => {
  isOrganizationAssistantsDashBoardOpened.value = true;
  localStorage.setItem(
    "orgid",
    AppConfigGlobal.CurrentOrganizationId.toString()
  );
  emits("assistantDashBoardOpened", organization);
};

const checkPointDashBoardClosed = () => {
  isSelectCheckpoints.value = false;
  emits("onDeSelectCheckpoints");
};

const onTicketSelected = (id: number) => {
  emits("onTicketSelected", id);
};
const selectCheckpoints = () => {
  emits("onSelectCheckpoints");
  isSelectCheckpoints.value = true;
};

const onAssistantsDashBoardClose = () => {
  isOrganizationAssistantsDashBoardOpened.value = false;
  AppConfigGlobal.CurrentOrganizationId = AppConfigGlobal.Organizations[0].id;
  emits("assistantDashBoardClosed");
};

const onAssistantSelected = () => {
  emits("assistantSelected");
};
onMounted(async () => {
  isUserConfigLoading.value = true;
  userConfiguration.value = await userService.GetUserConfiguration();

  if (!userConfiguration.value.organizations) {
    console.log("There is no organization for this user.");
    return;
  }

  AppConfigGlobal.UserId = userConfiguration.value.id;
  AppConfigGlobal.UserName = userConfiguration.value.name;
  AppConfigGlobal.UserImage = userConfiguration.value.image;
  AppConfigGlobal.Organizations = userConfiguration.value.organizations;
  //AppConfigGlobal.CurrentOrganizationId = AppConfigGlobal.Organizations[0].id;

  isUserConfigLoading.value = false;

  const orgid = authService.GetCookieValue("CurrentOrgId"); //set orgnaization name in cookies

  if (orgid !== undefined && orgid.trim() !== "") {
    AppConfigGlobal.CurrentOrganizationId = parseInt(orgid, 10);

    onOrganizationSelect(userConfiguration.value.organizations[0]);
  } else {
    AppConfigGlobal.CurrentOrganizationId =
      userConfiguration.value.organizations[0].id;
    authService.ClearCookie("CurrentOrgId");
    authService.CreateCookie(
      "CurrentOrgId",
      AppConfigGlobal.CurrentOrganizationId.toString()
    );
    onOrganizationSelect(userConfiguration.value.organizations[0]);
  }
  profileOrganizationName.value =
    authService.GetCookieValue("CurrentOrgName") ?? ""; //get orgnaization name in cookies
});

watch(
  () => props.isPreDefinedQuery,
  (newVal) => {
    if (newVal) {
      onOrganizationSelect(userConfiguration.value.organizations[0]);
    }
  }
);
</script>

<style scoped lang="css">
.v-enter-active {
  transition: all 0.2s ease-out;
  transition-delay: 0.2s;
}

.v-leave-active {
  transition: all 0.2s ease-in;
}

.v-enter-from {
  transform: translateX(0%);
  opacity: 0;
}

.v-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

/* .v-enter-from,
.v-leave-to {
  transform: translateX(10px);
  opacity: 0;
} */
</style>

<!--<style scoped lang="css">
.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dashboard-body {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px 0px;
}

.dashboard-footer {
}

.dashboard-body-overlay {
  position: absolute;
  width: 83%;
  height: 100%;
  right: 0;
  border-left: 1px solid
    var(&#45;&#45;Seperator-semi-transparent, rgba(255, 255, 255, 0.09));
}
.seperator {
  border: none;
  border-top: 1px solid var(&#45;&#45;separatorSemiTransparent);
  margin: 15px;
}

.v-enter-active {
  transition: all 0.2s ease-out;
}

.v-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.v-enter-from,
.v-leave-to {
  transform: translateX(40px);
  opacity: 0;
}

@media (max-width: 768px) {
  .dashboard {
    width: 72%;
    margin-left: 76px;
  }
}

@media (max-width: 600px) {
  .dashboard {
    width: auto;
    margin-left: 0px;
  }
}
</style>-->
