<template>
    <div class="user-profile p-3 border-t border-menuSelected mx-1 bg-backgroundSidebar">
        <div class="user-info flex items-center ">
            <div class="aspect-square rounded-full mr-3 flex align-middle justify-center">
            <img :src="AppConfigGlobal.UserImage"  @error="handleImageError"
                 class="user-avatar w-11 flex-none rounded-full" />
                </div>
            <div class="user-details flex-auto">
                <div class="user-name">{{ AppConfigGlobal.UserName }}</div>
                <div>
                    <div class="user-role text-sm text-grey" v-if="!props.profileOrganizationName">{{ AppConfigGlobal.Organizations[0]?.name }}</div>
                    <div class="user-role text-sm text-grey" v-else>{{ organizationName }}</div>
                </div>
            </div> 
            <span class="settings-icon flex-none" @click="openSettings">
            <IconGear v-if="!props.isSettingsOpened" />
            <IconHome v-else />
        </span>
            
        </div>
       
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, onMounted, watch } from "vue";
import IconGear from "@/components/icons/IconGear.vue";
import IconHome from "@/components/icons/IconHome.vue";
import { UserService } from "@/Services/UserService";
import type { IUserConfiguration } from "@/Models/Response/User/IUserConfiguration";
import AppConfigGlobal from "../../core/config/uiSettings";

const userService = new UserService();

const props = defineProps({
  isSettingsOpened: {
    type: Boolean,
    required: true,
    default: false,
  },
  profileOrganizationName: {
    type: String,
    required: true
  },
});
const organizationName = ref(props.profileOrganizationName);
const fallbackImage = 'https://ui-avatars.com/api/?name='; 
const handleImageError = () => {
    AppConfigGlobal.UserImage = fallbackImage+AppConfigGlobal.UserName;  
    };
const emits = defineEmits(["openSettings", "closeSettings"]);
const userConfiguration = ref<IUserConfiguration>({} as IUserConfiguration);
const openSettings = () => {
    if ( props.isSettingsOpened ) {
        window.location.reload();
    }
  emits("openSettings");
};

watch(
  () => props.profileOrganizationName,
  (newValue) => {
    if (newValue) {
      organizationName.value = newValue;
    } else {
      organizationName.value = AppConfigGlobal.Organizations[0]?.name;
    }
  },
  { immediate: true } // Trigger watcher on initialization
);

onMounted(async () => {
  //userConfiguration.value = await userService.GetUserConfiguration();

  //if (!userConfiguration.value.organizations) {
  //  console.log("There is no organization for this user.");
  //  return;
  //}

  //AppConfigGlobal.UserId = userConfiguration.value.id;
  //AppConfigGlobal.UserName = userConfiguration.value.name;
  //AppConfigGlobal.Organizations = userConfiguration.value.organizations;
});
</script>

<!-- <style scoped>
    .user-profile {
        border-radius: 15px 0px;
        background-color: #071a2b;
        display: flex;
        min-height: 50px;
        width: 100%;
        max-width: 400px;
        align-items: center;
        justify-content: space-between;
        padding: 5px 13px;
    }

    @media (max-width: 1440px) {
        .user-profile {
            padding: 5px 20px;
            margin-top: -12px;
        }
    }

    @media (max-width: 1024px) {
        .user-profile {
            padding: 5px 20px;
            margin-top: -10px;
        }
    }

    @media (max-width: 768px) {
        .user-profile {
            margin-left: 74px;
        }
    }

    @media (max-width: 600px) {
        .user-profile {
            margin-left: 18px;
            width: auto;
        }
    }

    .user-info {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .user-avatar {
        aspect-ratio: 1;
        width: 40px;
        border-radius: 200px;
    }

    .user-details {
        display: flex;
        flex-direction: column;
    }

    .user-name {
        color: #fff;
    }

    .user-role {
        color: rgba(255, 255, 255, 0.4);
    }

    .settings-icon {
        aspect-ratio: 1;
        object-fit: contain;
        width: 25px;
    }
</style> -->
