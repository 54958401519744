<template>
  <svg
    width="275"
    height="153"
    viewBox="0 0 275 153"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.7635 137.504C31.3439 137.504 35.0085 137.299 37.5145 135.583C39.1641 134.45 40.0016 132.919 40.0016 131.023C40.0016 128.929 39.139 127.281 37.4452 126.129C34.6999 124.264 30.8276 124.512 30.6576 124.518L30.9661 127.071C30.9661 127.071 33.5351 126.928 35.1407 128.031C36.0852 128.681 36.5637 129.685 36.5637 131.023C36.5637 132.194 36.1103 133.068 35.1911 133.7C33.1258 135.119 29.3227 134.933 29.2849 134.933L29.0016 137.485C29.0457 137.485 29.329 137.504 29.7698 137.504H29.7635Z"
      fill="#A7D0F8"
    />
    <path
      d="M33.0016 121.504H0.00158691L3.23559 152.504H29.5201L33.0016 121.504Z"
      fill="#A7D0F8"
    />
    <path
      d="M21.7739 104.707C21.7739 110.815 13.017 113.666 11.7669 116.516C10.5167 119.367 25.5258 116.109 30.1131 105.929C34.7005 95.7485 24.2764 94.1196 25.1096 88.4186C25.9428 82.7177 35.9502 79.0533 37.6184 75.3878C39.2867 71.7224 35.1171 68.058 32.6149 67.6508C30.1128 67.2436 25.9443 67.6508 21.7739 69.2796C17.6036 70.9085 7.59727 75.3878 8.01423 85.5682C8.43119 95.7485 21.7739 98.599 21.7739 104.707Z"
      fill="white"
    />
    <path
      d="M198.002 130.504H159.002V152.504H198.002V130.504Z"
      fill="#11385B"
    />
    <path
      d="M82.2555 111.926H274.015V118.606C274.015 125.606 268.325 131.296 261.325 131.296H94.9555C87.9555 131.296 82.2655 125.606 82.2655 118.606V111.926H82.2555Z"
      fill="#E0F2FE"
    />
    <path
      d="M91.1255 0.175781H265.125C270.025 0.175781 274.005 4.15577 274.005 9.05577V112.236H82.2555V9.05577C82.2555 4.15577 86.2355 0.175781 91.1355 0.175781H91.1255Z"
      fill="#E0F2FE"
    />
    <path
      d="M263.425 8.23047H94.0446C91.8354 8.23047 90.0446 10.0213 90.0446 12.2305V101.31C90.0446 103.52 91.8354 105.31 94.0446 105.31H263.425C265.634 105.31 267.425 103.52 267.425 101.31V12.2305C267.425 10.0213 265.634 8.23047 263.425 8.23047Z"
      fill="#11385B"
    />
  </svg>
</template>
