<template>
  <div
    class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center"
  >
    <div
      class="bg-[var(--color-background)] border-[0.1875rem] border-lightBlue w-[21.875rem] p-6 rounded-[40px]"
    >
      <div
        class="w-16 h-16 p-5 rounded-[20px] m-auto flex justify-center items-center border border-lightBlue"
      >
        <div class="flex justify-center items-center">
          <svg
            class="key-01"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 48 48"
            fill="none"
          >
            <path
              d="M34 17.9999C34 16.9762 33.6094 15.9526 32.8284 15.1716C32.0474 14.3905 31.0237 14 30 14M30 30C36.6274 30 42 24.6274 42 18C42 11.3726 36.6274 6 30 6C23.3726 6 18 11.3726 18 18C18 18.5474 18.0366 19.0862 18.1076 19.6141C18.2244 20.4824 18.2827 20.9165 18.2434 21.1912C18.2025 21.4773 18.1504 21.6315 18.0094 21.8838C17.874 22.126 17.6354 22.3646 17.1583 22.8417L6.93726 33.0627C6.59135 33.4086 6.4184 33.5816 6.29472 33.7834C6.18506 33.9624 6.10425 34.1575 6.05526 34.3615C6 34.5917 6 34.8363 6 35.3255V38.8C6 39.9201 6 40.4802 6.21799 40.908C6.40973 41.2843 6.71569 41.5903 7.09202 41.782C7.51984 42 8.07989 42 9.2 42H14V38H18V34H22L25.1583 30.8417C25.6354 30.3646 25.874 30.126 26.1162 29.9906C26.3685 29.8496 26.5227 29.7975 26.8088 29.7566C27.0835 29.7173 27.5176 29.7756 28.3859 29.8924C28.9138 29.9634 29.4526 30 30 30Z"
              stroke="#A7D0F8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <h1
        class="text-white text-center mt-[16px] text-[1.813rem] font-semibold"
      >
        Check your email
      </h1>
      <p class="text-white text-center text-base font-normal">
        We sent a verification code to {{ email }}
      </p>
      <ErrorMessage v-if="errorMessage" :message="errorMessage" class="mt-2" />
      <form class="mt-5">
        <div class="flex justify-center items-center">
          <div class="flex gap-3.5">
            <div
              class="w-16 h-16 rounded-lg border-solid divide-x-2 divide-y-2 py-0.5 px-2 flex justify-center items-center gap-2 border border-lightBlue"
            >
              <input
                class="text-sky-200 w-16 rounded-lg border-none outline-none text-center text-[40px] font-medium bg-transparent focus:shadow-focus"
                v-model="tokenDigits[0]"
                @input="moveToNext(0)"
                @keydown="handleKeydown($event, 0)"
                @focus="clearValidation"
                maxlength="1"
                type="text"
              />
            </div>
            <div
              class="w-16 h-16 rounded-lg border-solid divide-x-2 divide-y-2 py-0.5 px-2 flex justify-center items-center gap-2 border border-lightBlue"
            >
              <input
                class="text-sky-200 w-16 rounded-lg border-none outline-none text-center text-[40px] font-medium bg-transparent focus:shadow-focus"
                v-model="tokenDigits[1]"
                @input="moveToNext(1)"
                @keydown="handleKeydown($event, 1)"
                @focus="clearValidation"
                maxlength="1"
                type="text"
              />
            </div>
            <div
              class="w-16 h-16 rounded-lg border-solid divide-x-2 divide-y-2 py-0.5 px-2 flex justify-center items-center gap-2 border border-lightBlue"
            >
              <input
                class="text-sky-200 w-16 rounded-lg border-none outline-none text-center text-[40px] font-medium bg-transparent focus:shadow-focus"
                v-model="tokenDigits[2]"
                @input="moveToNext(2)"
                @keydown="handleKeydown($event, 2)"
                @focus="clearValidation"
                maxlength="1"
                type="text"
              />
            </div>
            <div
              class="w-16 h-16 rounded-lg border-solid divide-x-2 divide-y-2 py-0.5 px-2 flex justify-center items-center gap-2 border border-lightBlue"
            >
              <input
                class="text-sky-200 w-16 rounded-lg border-none outline-none text-center text-[40px] font-medium bg-transparent focus:shadow-focus"
                v-model="tokenDigits[3]"
                @input="moveToNext(3)"
                @keydown="handleKeydown($event, 3)"
                @focus="clearValidation"
                maxlength="1"
                type="text"
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          class="w-[18.75rem] h-[3.25rem] rounded-2xl bg-white border-none cursor-pointer mt-4 focus:outline-0 focus:shadow-none focus-within:outline-0 focus-within:shadow-none"
          @click.prevent="verifyOtp"
          :disabled="loading || resendEmailCompleted"
        >
          <LoginSpinner
            v-if="loading"
            style="
              position: relative;
              left: 45%;
              top: 0%;
              border-top: 2px solid black;
            "
          />
          <span v-else class="text-lg text-[#031525] font-medium"
            >Verify Email</span
          >
        </button>
      </form>
      <!-- <button type="submit" class="reset-password__button">Enter code manually</button> -->
      <!-- <button type="submit" class="reset-password__button">Continue</button> -->
      <div class="flex justify-center items-center mt-5 gap-1">
        <p class="bodySmall text-left text-[#CBD6E3] text-sm">
          Didn't receive the email?
        </p>
        <a
          class="bodySmallSemibold text-left text-sm cursor-pointer hover:bg-transparent text-[#A7D0F8]"
          :class="{ disabled: resendEmailCompleted || loading }"
          @click="resendemail"
          >Click to resend</a
        >
      </div>
      <div class="flex justify-center items-center mt-5 gap-1.5 ml-1.5">
        <div class="text-white">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8327 6.9974H1.16602M1.16602 6.9974L6.99935 12.8307M1.16602 6.9974L6.99935 1.16406"
              stroke="white"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div @click="BackToLogin()">
          <a
            :href="`${loginUrl}`"
            class="bodySmall text-center block text-white text-sm font-medium"
          >
            Back to log in</a
          >
        </div>
      </div>
    </div>
    <MessageToaster
      v-show="showToaster"
      :message="toasterMessage"
      isSuccess="oops"
      @update:showToaster="showToaster = $event"
      class="-mb-[2rem]"
    />
    <MessageToaster
      v-show="successToaster"
      :message="toasterMessage"
      :title="title"
      isSuccess="success"
      @update:successToaster="successToaster = $event"
      class="-mb-[2rem]"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import MessageToaster from "../components/MessageToaster.vue";
import AuthService, {
  type UserRenewTokenVerificationViewModel,
} from "@/Services/AuthService";
import ErrorMessage from "../components/common/ErrorMessage.vue";
import axios from "axios";
import { Device } from "@capacitor/device";
import LoginSpinner from "./common/Spinner.vue";
import { UserService } from "@/Services/UserService";

const userService = new UserService();
// import { title } from "process";
export default defineComponent({
  components: {
    MessageToaster,
    ErrorMessage,
    LoginSpinner,
  },
  data() {
    return {
      tokenDigits: ["", "", "", ""],
      loginUrl: `${window.location.origin}/auth/login`,
      email: "", // Directly use this to display and use the email
      userTokenVerificationViewModel: {
        emailKey: "",
        token: 0,
        isRegister: false,
      },

      errors: {},
      serverError: "",
      showToaster: false,
      successToaster: false,
      toasterMessage: "",
      title: "",
      errorMessage: "",
      loading: false,
      resendEmailCompleted: false,
      fullName: "",
      userId: 0,
      router: useRouter(),
      route: useRoute(),
      authRequest: {
        UserName: "",
        EmailKey: "",
        Token: 0,
        DeviceInformation: {
          device: "",
          location: "",
          latitude: "",
          longitude: "",
          deviceId: "",
          operatingSystem: "",
          platform: "",
        },
      } as UserRenewTokenVerificationViewModel,
    };
  },
  created() {
    // Retrieve the email directly from sessionStorage and store it in the data
    this.email = sessionStorage.getItem("userEmail") || "";
  },
  async mounted() {
    this.getUserLocation();
    this.showToaster = false;

    const ekey = this.route.query.emailKey || "";
    if (ekey == "") {
      return;
    }
    if (typeof ekey !== "string" || ekey.trim() === "") {
      return;
    }
    // const keyDetails = await userService.GetDetailsByEmailKey(ekey);
    // if (keyDetails) {
    //   this.router.push("/timeout");
    // }
  },
  methods: {
    async verifyOtp() {
      this.showToaster = false;
      this.successToaster = false;
      this.toasterMessage = "";
      const token = this.tokenDigits.join("");
      const isRegistering = sessionStorage.getItem("Register") == "true";
      const emailKey = this.route.query.emailKey as string;
      if (token.length !== 4 || !/^\d{4}$/.test(token)) {
        this.toasterMessage = "";
        this.toasterMessage = "";
        this.showToaster = true;
        this.errorMessage = "";
        this.toasterMessage =
          "Looks like this code didn’t work. Maybe it got lost on its way from the email? Let’s try that again!";
        setTimeout(() => {
          this.showToaster = false;
        }, 2000);
        return;
      }

      if (token.length === 4 && emailKey) {
        this.userTokenVerificationViewModel.emailKey = emailKey;
        this.userTokenVerificationViewModel.token = parseInt(token, 10);
        this.userTokenVerificationViewModel.isRegister = isRegistering;
        const authService = new AuthService();
        this.loading = true;
        this.resendEmailCompleted = true;
        const ekey = this.route.query.emailKey || "";
        if (ekey == "") {
          return;
        }
        if (typeof ekey !== "string" || ekey.trim() === "") {
          return;
        }
        const isLoginstring = sessionStorage.getItem("isLogin") || "false";
        const isLogin = isLoginstring === "true";
        const keyDetails = await userService.GetDetailsByEmailKey(
          ekey,
          isLogin
        );
        if (keyDetails) {
          this.showToaster = true;
          this.toasterMessage =
            "Looks like this code didn’t work. Maybe it got lost on its way from the email? Let’s try that again!";
          this.loading = false;
          setTimeout(() => {
            this.showToaster = false;
            this.resendEmailCompleted = false;
          }, 2000);
          return;
        }
        try {
          const response = await authService.VerifyUser(
            this.userTokenVerificationViewModel
          );
          if (response && sessionStorage.getItem("Register") != "true") {
            sessionStorage.removeItem("Register");
            this.errorMessage = "";
            (this.authRequest.UserName = this.email),
              (this.authRequest.EmailKey = emailKey);
            this.authRequest.Token = parseInt(token, 10);

            // const authResponse = await authService.GetLoginTokens(
            //   this.authRequest
            // );

            // authService.CreateCookie("freddyUserId", authResponse.token);
            // authService.CreateCookie(
            //   "refreshToken",
            //   authResponse.refreshToken.token
            // );
            // authService.CreateCookie(
            //   "tokenExpiration",
            //   authResponse.refreshToken.expiry
            // );
            // authService.CreateCookie("deviceId", authResponse.deviceId);

            // this.router.push("/logged");
            try {
              const authResponse = await authService.GetLoginTokens(
                this.authRequest
              );

              authService.CreateCookie("freddyUserId", authResponse.token);
              authService.CreateCookie(
                "refreshToken",
                authResponse.refreshToken.token
              );
              authService.CreateCookie(
                "tokenExpiration",
                authResponse.refreshToken.expiry
              );
              authService.CreateCookie("deviceId", authResponse.deviceId);

              this.router.push("/logged");
            } catch (error) {
              this.loading = false;
              this.resendEmailCompleted = true;
              console.error("Error during login process:", error);
              this.router.push("/registration/status/failed");
            }
          } else {
            this.errorMessage = "";
            this.router.push("/updateusername");
          }
        } catch (error: unknown) {
          this.resendEmailCompleted = true;
          this.loading = false;

          if (error instanceof Error && error.hasOwnProperty("response")) {
            const axiosError = error as any;
            if (
              axiosError.response &&
              axiosError.response.data &&
              axiosError.response.data.message
            ) {
              this.showToaster = true;
              if (axiosError.response.data.message == "Invalid token.") {
                this.errorMessage = "";
                this.toasterMessage =
                  "Looks like this code didn’t work. Maybe it got lost on its way from the email? Let’s try that again!";
              } else {
                this.resendEmailCompleted = false;
                this.loading = false;
                this.errorMessage = "";
                this.toasterMessage =
                  "Looks like this code didn’t work. Maybe it got lost on its way from the email? Let’s try that again!";
              }
            }
          }
        } finally {
          setTimeout(() => {
            this.showToaster = false;
            this.successToaster = false;
            this.loading = false;
          }, 2000);
        }
      } else {
        this.loading = false;
        this.errorMessage = "Enter a valid token.";
        setTimeout(() => {
          this.showToaster = false;
          this.successToaster = false;
        }, 2000);
      }
    },
    async resendemail() {
      this.resendEmailCompleted = true;
      const email = sessionStorage.getItem("userEmail") || "";
      const authService = new AuthService();
      const emailDetails = await authService.GetEmailDetails(this.email);
      this.fullName = emailDetails.FullName;
      this.userId = emailDetails.userId;
      // const fullName = sessionStorage.getItem("fullName") || "";
      // const userId = sessionStorage.getItem("userId") || "";
      const isLoginstring = sessionStorage.getItem("isLogin") || "false";
      const isLogin = isLoginstring === "true";

      try {
        const data = {
          email: email,
          fullName: this.fullName,
          userId: this.userId,
          isLogin: isLogin,
        };

        const response = await authService.ResendVerificationEmail(data);
        this.successToaster = true;
        this.title = "Verification resent";
        this.toasterMessage =
          "We've resent the verification email, please check your inbox.";
        this.resendEmailCompleted = false;

        setTimeout(() => {
          this.router.push({
            path: "/token/verify",
            query: { emailKey: response.emailKey },
          });
        }, 1200);
      } catch (error) {
        console.log(error);
        this.resendEmailCompleted = false;
        this.showToaster = true;
        this.toasterMessage = "Something went wrong. Please try again.";
      } finally {
        setTimeout(() => {
          this.resendEmailCompleted = false;
          this.showToaster = false;
          this.successToaster = false;
          this.toasterMessage = "";
        }, 2200);
      }
    },
    BackToLogin() {
      this.$router.push("/auth/login");
    },

    handleError(error: Error) {
      console.error("An error occurred:", error);
    },
    clearValidation() {
      this.errorMessage = "";
    },
    moveToNext(index: number) {
      if (this.tokenDigits[index].length === 1) {
        this.resendEmailCompleted = false;
        const nextInput = this.$el.querySelectorAll("input")[index + 1];
        if (nextInput) {
          nextInput.focus();
        }
      }
    },
    handleKeydown(event: KeyboardEvent, index: number) {
      const inputFields = this.$el.querySelectorAll("input");
      if (event.key === "ArrowLeft" && index > 0) {
        (inputFields[index - 1] as HTMLElement).focus();
      } else if (event.key === "ArrowRight" && index < inputFields.length - 1) {
        (inputFields[index + 1] as HTMLElement).focus();
      } else if (event.key === "Backspace" && this.tokenDigits[index] === "") {
        if (index > 0) {
          (inputFields[index - 1] as HTMLElement).focus();
        }
      }
    },
    async getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.setLocation,
          this.showError
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
      this.getDeviceDetails();
    },

    async getDeviceDetails() {
      try {
        const deviceId = await Device.getId();
        const deviceinfo = await Device.getInfo();
        this.authRequest.DeviceInformation.deviceId = deviceId.identifier;
        this.authRequest.DeviceInformation.device = deviceinfo.model;
        this.authRequest.DeviceInformation.operatingSystem =
          deviceinfo.operatingSystem;
        this.authRequest.DeviceInformation.platform = deviceinfo.platform;
      } catch (error: any) {
        this.handleError(error);
      }
    },

    async setLocation(position: GeolocationPosition) {
      try {
        const { latitude, longitude } = position.coords;
        this.authRequest.DeviceInformation.latitude = latitude.toString();
        this.authRequest.DeviceInformation.longitude = longitude.toString();

        await this.getLocationName(latitude.toString(), longitude.toString());
      } catch (error: any) {
        this.handleError(error);
      }
    },

    showError(error: any) {
      console.error(error.message);
    },

    async getLocationName(latitude: string, longitude: string) {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10&addressdetails=1`
        );
        const address = response.data.address;

        const locname = `${
          address.city || address.town || address.village || ""
        }, ${address.state || ""}, ${address.country || ""}`;

        this.authRequest.DeviceInformation.location = locname;
      } catch (error) {
        console.error("Error fetching location name:", error);
      }
    },
  },
});
</script>
<style scoped>
.disabled {
  pointer-events: none;
  cursor: none;
}
</style>
