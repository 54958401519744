<template>
  <!--    <header class="freddy-font theme-dark"></header>-->
  <router-view class="freddy-font theme-dark" />
  <!-- <automaticallyUserChoosen /> -->
</template>
<style scoped></style>

<script setup lang="ts">
import UserLogin from "./components/UserLogin.vue";
import UserForgotPassword from "./components/UserForgotPassword.vue";
import UserConfirmPassword from "./components/UserConfirmPassword.vue";
import { useRouter } from "vue-router";
import { onMounted, onUnmounted, ref } from "vue";
import UserRegistration from "./components/UserRegistration.vue";
import EmailCodeVerification from "./components/ForgotPasswordEmailCodeVerification.vue";
import EmailCodeInput from "./components/EmailCodeInput.vue";
import AccountSuccesfullyCreated from "./components/AccountSuccesfullyCreated.vue";
import AccountRegisterWaiting from "./components/AccountRegisterWaiting.vue";
import AccountRegistrationLastStep from "./components/AccountRegistrationUserNameUpdate.vue";
import type { ILoggedInDeviceInformation } from "@/Models/Common/ILoggedInDeviceInformation";
import AuthService from "./Services/AuthService";
import { jwtDecode } from "jwt-decode";
import FreddyAssistant from "./components/FreddyAssistant.vue";
import router from "./router";
import LoadingSpinner from "./components/spinners/LoadingSpinner.vue";
import TokenManager from "./Services/TokenManager";
import test from "./components/test.vue";
import { UserService } from "@/Services/UserService";
import type { IUserConfiguration } from "@/Models/Response/User/IUserConfiguration";
import AppConfigGlobal from "./core/config/uiSettings";
import UserRegistrationCompanyWelcome from "./components/UserRegistrationCompanyWelcome.vue";
import automaticallyUserChoosen from "./components/automaticallyUserChoosen.vue";
import AccountRegistrationAccessDenied from "./components/AccountRegistrationAccessDenied.vue";

const isLoading = ref<boolean>(false);
const userConfiguration = ref<IUserConfiguration>({} as IUserConfiguration);
const userService = new UserService();
const authService = new AuthService();
const tokenManager = TokenManager.getInstance();
const deviceInformation = ref<ILoggedInDeviceInformation>({});

const resolveOSName = () => {
  if (/mobile/i.test(navigator.userAgent)) {
    deviceInformation.value.deviceType = "Mobile";
  } else if (/tablet/i.test(navigator.userAgent)) {
    deviceInformation.value.deviceType = "Tablet";
  } else {
    deviceInformation.value.deviceType = "Desktop";
  }
};

const getBrowserName = (): string => {
  const ua = navigator.userAgent.toLowerCase();
  if (/chrome|crios|crmo/i.test(ua) && !/edge|edg|opera|opr/i.test(ua)) {
    return "Chrome";
  } else if (/firefox|fxios/i.test(ua)) {
    return "Firefox";
  } else if (/safari/i.test(ua) && !/chrome|crios|crmo/i.test(ua)) {
    return "Safari";
  } else if (/edge|edg/i.test(ua)) {
    return "Edge";
  } else if (/opera|opr/i.test(ua)) {
    return "Opera";
  } else if (/msie|trident/i.test(ua)) {
    return "Internet Explorer";
  } else {
    return "Unknown";
  }
};

const resolveOSVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  if (/iphone|ipad|ipod/.test(ua)) {
    const versionMatch = ua.match(/os (\d+(_\d+)*)/);
    if (versionMatch) {
      deviceInformation.value.operatingSystem = "iOS";
      deviceInformation.value.osVersion = versionMatch[1].replace("_", ".");
    }
  } else if (/android/.test(ua)) {
    const versionMatch = ua.match(/android (\d+(\.\d+)*)/);
    if (versionMatch) {
      deviceInformation.value.operatingSystem = "Android";
      deviceInformation.value.osVersion = versionMatch[1];
    }
  } else if (/windows/.test(ua)) {
    const versionMatch = ua.match(/windows nt (\d+(\.\d+)*)/);
    if (versionMatch) {
      deviceInformation.value.operatingSystem = "Windows";
      deviceInformation.value.osVersion = versionMatch[1];
    }
  } else if (/macintosh/.test(ua)) {
    const versionMatch = ua.match(/mac os x (\d+(_\d+)*)/);
    if (versionMatch) {
      deviceInformation.value.operatingSystem = "Mac OS";
      deviceInformation.value.osVersion = versionMatch[1].replace("_", ".");
    }
  } else if (/linux/.test(ua)) {
    deviceInformation.value.operatingSystem = "Linux";
  } else {
    deviceInformation.value.operatingSystem = "Unknown OS";
  }
};

const resolveDeviceResolution = () => {
  const width = window.screen.width;
  const height = window.screen.height;
  deviceInformation.value.screenResolution = `${width} x ${height}`;
};

const resolveStorageCapacity = async () => {
  if (
    "navigator" in window &&
    "storage" in navigator &&
    "estimate" in navigator.storage
  ) {
    const estimate = await navigator.storage.estimate();
    if (estimate) {
      const total = estimate.quota ? estimate.quota / (1024 * 1024 * 1024) : 0; // Convert bytes to GB
      const used = estimate.usage ? estimate.usage / (1024 * 1024 * 1024) : 0; // Convert bytes to GB
      deviceInformation.value.storageCapacity = `${total.toFixed(2)} GB`;
    }
  }
};

const getGeolocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      (deviceInformation.value.locationX = `${position.coords.latitude}`),
        (deviceInformation.value.locationY = `${position.coords.longitude}`);
    });
  }
};

const setTheme = (theme: string) => {
  document.querySelector("html")?.setAttribute("data-theme", theme);
};

onMounted(async () => {
  setTheme("default");
  const tokenManager = TokenManager.getInstance();
  const token = authService.GetCookieValue("freddyUserId");
  const tokenExpiration = tokenManager.getTokenExpiration();
  if (token && tokenExpiration) {
    if (Date.now() < tokenExpiration.getTime()) {
      tokenManager.startBackgroundTokenRefresh();
    }
  }
  //window.addEventListener('load', () => tokenManager.initializeTokenManagement());
  window.addEventListener("storage", handleStorageEvent);
});
const handleStorageEvent = (event: StorageEvent) => {
  if (event.key === "logout") {
    document.cookie =
      "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    router.push("/auth/login");
  }
};
onUnmounted(() => {
  window.removeEventListener("storage", handleStorageEvent);
});
</script>
