import { ref, onMounted, onBeforeUnmount } from "vue";

export default function useNetworkStatus() {
  const isOnline = ref(navigator.onLine);

  const updateOnlineStatus = () => {
    isOnline.value = navigator.onLine;
  };

  onMounted(() => {
    // Add event listeners for online and offline
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
  });

  onBeforeUnmount(() => {
    // Remove event listeners to prevent memory leaks
    window.removeEventListener("online", updateOnlineStatus);
    window.removeEventListener("offline", updateOnlineStatus);
  });

  return { isOnline, updateOnlineStatus };
}
