<template>
  <div
    class="bg-backgroundSidebar inline-block w-full border border-menuSelected focus-within:border-white rounded-lg min-w-[200px] p-2">
    <button @click="toggleDropdown"
      class="border-none cursor-pointer flex items-center justify-between w-full text-white focus-visible:outline-0 font-normal text-base">
      <div style="display: flex; align-items: center">
        <div class="rounded-full aspect-square w-7 overflow-hidden flex mr-2">
          <img v-if="selectedItem" :src="getFlagUrl(selectedItem.code)" style="" alt="Avatar"
            class="rounded-full mr-[10px] object-cover max-w-7 max-h-7" />
        </div>
        <span v-if="selectedItem">{{ selectedItem.name }}</span>
      </div>
      <div> <i :class="dropdownOpen ? '-rotate-45' : 'rotate-[135deg]'"
          class="inline-block w-2.5 h-2.5 border-t-2 border-r-2  text-[#ffff] border-white transform ml-1"></i>
      </div>
    </button>
    <div v-if="dropdownOpen" ref="dropdown"
      class="absolute top-full left-0 bg-menuSelected border border-buttonBlue focus-within:border-white shadow-lg z-[1000] w-full rounded-lg h-[228px] overflow-y-scroll">
      <div v-for="(item, index) in dataList" :key="index" @click="selectItem(item)"
        class="p-2.5 cursor-pointer flex items-center">

        <img style="width: 30px; height: 30px;" :src="getFlagUrl(item.code)"
          class="rounded-full mr-2 object-cover max-w-8 max-h-8" />
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { ICountry } from "@/Models/Common/ICountry";
import { ref, type PropType } from "vue";

export default {
  data() {
    return {
      dropdownOpen: false,
    };
  },
  props: {
    dataList: {
      type: Array as PropType<ICountry[]>, // Define as an array of ICountry
      default: () => [],
    },

    selectedItem: {
      type: Object as PropType<ICountry | null>, // Define as ICountry or null
      default: null,
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectItem(item: ICountry) {
      this.dropdownOpen = false;
      this.$emit("selectedcountryId", item.id);
    },
    handleClickOutside(event: { target: any; }) {

      const dropdown = this.$refs.dropdown;
      if (dropdown && !(dropdown as HTMLElement).contains(event.target) && this.dropdownOpen) {
        this.dropdownOpen = false;
      }
    },

    getFlagUrl(code: string) {
      return `https://flagpedia.net/data/flags/h80/${code}.png`; // Example flag URL pattern
    }
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
  },

};
</script>

<style scoped>
/* .dropdown {
        background: var(--selected, #11385b);
        display: inline-block;
        width: 100%;
        border: 1px solid;
        border-color: var(--text-graph, rgba(181, 30, 30, 0.4));
        border-radius: 10px;
        min-width: 200px;
        padding: 10px;
    } */

/* .dropdown-button {
        background-color: #c0c0c000;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: white;
    } 
  */
/* .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    } */

/* .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        background: var(--selected, #11385b);
        border-color: var(--text-graph, rgba(255, 255, 255, 0.4));
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        width: 100%;
        border-radius: 10px; 
        height: 228px;
        overflow-y: scroll;
    } */

/* .dropdown-item {
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
 */
/* .dropdown-item:hover {
  background-color: hsla(160, 100%, 37%, 0.2);
}

.arrow-down::after,
.arrow-up::after {
  content: "";
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
}

.arrow-down::after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-up::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
} */
</style>
