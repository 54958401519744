import type { IUserDetails, IUserRequest } from "@/Models/SettingPages/ProfileSettings";
import AppConfigGlobal from "../core/config/uiSettings";
import Core from "./Core";
import type { ICountry } from "@/Models/Common/ICountry";
import type { ITimezone } from "../Models/Common/ITimezone";

export class SettingsPageService extends Core {

    public async Get_ProfileSettings(): Promise<IUserDetails> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/profile`;
            return await this.PerformGet<IUserDetails>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return err;
        }
    }

    public async Get_Country(): Promise<ICountry[]> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/country`;
            return await this.PerformGet<ICountry[]>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return [];
        }
    }
    public async Get_Timezone(): Promise<ITimezone[]> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/timezone`;
            return await this.PerformGet<ITimezone[]>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return err;
        }
    }
    public async Update_User(userDetails: IUserRequest): Promise<boolean> {
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user`;
        return this.PerformPut(requestUrl, userDetails);
    }
    public async ClearAllChat(): Promise<boolean> {
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/V2/DeleteAllThreadsByUserId`;
        return this.PerformDelete(requestUrl);
    }
}