<template>
  <div
    class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center"
  >
    <LoadingSpinner v-if="isBlurred" />
    <div
      v-else
      class="bg-[var(--color-background)] border-[0.188rem] border-lightBlue w-[350px] p-5 rounded-[2.5rem]"
    >
      <div>
        <div
          class="w-16 h-16 p-5 border-[0.063rem] border-lightBlue rounded-[20px] flex justify-center items-center m-auto"
        >
          <IconKey class="flex justify-center items-center" />
        </div>
        <h1
          class="text-[#fff] text-center mt-[15px] text-[1.813rem] font-semibold"
        >
          Set new password
        </h1>
        <p
          class="text-[#fff] text-center mt-[7px] mb-5 text-[14px] leading-[16.94px]"
        >
          Your new password must be different from previously used passwords.
        </p>
        <!-- <form class="confirm-password__form">
                  <div class="confirm-password__input-container">
                    <input type="email" class="textMdSemibold confirm-password__email-input" placeholder="Enter your email" required />
                  </div>
                  <button type="submit" class="body confirm-password__button">Reset password</button>
                </form> -->
        <div
          v-if="notMatchPassword"
          class="flex text-[#E71D41] mb-2.5 items-center"
        >
          <span class="mr-2.5 w-4 h-4 flex-shrink-0">
            <IconCircleVue />
          </span>
          <p class="error-message text-[0.813rem]">{{ responseMessage }}</p>
        </div>
        <form @submit.prevent="resetPassword">
          <div class="mb-[0.938rem]">
            <div class="relative">
              <input
                @input="checkNewPassword"
                v-model="newPassword"
                :type="showPassword ? 'password' : 'text'"
                placeholder="Password"
                class="w-[19.4rem] h-12 p-[0.75rem_0.75rem_0.75rem_1rem] rounded-[0.5rem]  bg-menuSelected text-white text-[0.875rem] focus:outline-none focus:shadow-none placeholder:text-textGrey pr-[3rem]"
                @focus="clearerrors"
                :class="{
                  'border border-[#E71D41]':
                    newpasswordEmpty || !isPasswordValid || notMatchPassword,
                }"
              />
              <span class="absolute top-[0.938rem] right-[1rem] cursor-pointer">
                <span v-if="showPassword">
                  <IconEye @click="toggleShowPassword" />
                </span>
                <span v-else>
                  <IconEyeSlash @click="toggleShowPassword" />
                </span>
              </span>

              <div
                v-if="newpasswordEmpty"
                class="flex text-[#E71D41] mb-2.5 items-center mt-1.5"
              >
                <span class="mr-2.5 w-4 h-4 flex-shrink-0">
                  <IconCircleVue />
                </span>
                <p class="error-message text-[0.813rem]">
                  {{ passwordresponseMessage }}
                </p>
              </div>
            </div>
          </div>
          <div class="mb-[0.938rem]">
            <div class="relative">
              <input
                @input="checkConfirmPassword"
                v-model="confirmPassword"
                :type="showConfirmPassword ? 'password' : 'text'"
                placeholder="Confirm Password"
                class="w-[19.4rem] h-12 p-[0.75rem_0.75rem_0.75rem_1rem] rounded-[0.5rem] bg-menuSelected text-white text-[0.875rem] focus:outline-none focus:shadow-none placeholder:text-textGrey pr-[3rem]"
                @focus="clearerrors"
                :class="{
                  'border border-[#E71D41]':
                    newpasswordEmpty || !isPasswordValid || notMatchPassword,
                }"
              />

              <span class="absolute top-[0.938rem] right-[1rem] cursor-pointer">
                <span v-if="showConfirmPassword">
                  <IconEye @click="toggleShowConfirmPassword" />
                </span>
                <span v-else>
                  <IconEyeSlash @click="toggleShowConfirmPassword" />
                </span>
              </span>

              <div
                v-if="confirmpasswordEmpty"
                class="flex text-[#E71D41] mb-2.5 items-center mt-1.5"
              >
                <span class="mr-2.5 w-4 h-4 flex-shrink-0">
                  <IconCircleVue />
                </span>
                <p class="error-message text-[0.813rem]">
                  {{ confirmpasswordresponseMessage }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="bodySmall confirm-password__instructions">
            <p>
              <svg
                id="passwordCheckIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="svgFillColor"
              >
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  :fill="svgFillColor1"
                  fill-opacity="0.6"
                />
                <path
                  d="M6.25 10L8.75 12.5L13.75 7.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="check-text">Must be at least 8 characters</span>
            </p>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="svgFillColor"
              >
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  :fill="svgFillColor2"
                  fill-opacity="0.6"
                />
                <path
                  d="M6.25 10L8.75 12.5L13.75 7.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="check-text">Must contain one special character</span>
            </p>
          </div> -->
          <div
            class="flex flex-col gap-3 items-start justify-start self-stretch flex-shrink-0 relative mb-4"
          >
            <div
              class="flex flex-row gap-2 items-start justify-start self-stretch flex-shrink-0 relative"
              v-if="isPasswordLongEnough"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#A7D0F8" />
                <path
                  d="M6.25 10L8.75 12.5L13.75 7.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                class="text-sm font-medium leading-4 text-[#cbd6e3] text-left relative self-stretch flex-1 flex items-center justify-start"
              >
                Must be at least 8 characters
              </div>
            </div>
            <div
              class="flex flex-row gap-2 items-start justify-start self-stretch flex-shrink-0 relative"
              v-if="isValidPassword"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#A7D0F8" />
                <path
                  d="M6.25 10L8.75 12.5L13.75 7.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                class="text-sm font-medium leading-4 text-[#cbd6e3] text-left relative self-stretch flex-1 flex items-center justify-start bodySmall"
              >
                Must contain one special character
              </div>
            </div>
            <div
              class="flex flex-row gap-2 items-start justify-start self-stretch flex-shrink-0 relative"
              v-if="!isPasswordLongEnough"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M6.25 10L8.75 12.5L13.75 7.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                class="text-sm font-medium leading-4 text-[#cbd6e3] text-left relative self-stretch flex-1 flex items-center justify-start bodySmall"
              >
                Must be at least 8 characters
              </div>
            </div>
            <div
              class="flex flex-row gap-2 items-start justify-start self-stretch flex-shrink-0 relative"
              v-if="!isValidPassword"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M6.25 10L8.75 12.5L13.75 7.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                class="text-sm font-medium leading-4 text-[#cbd6e3] text-left relative self-stretch flex-1 flex items-center justify-start"
              >
                Must contain one special character
              </div>
            </div>
          </div>
          <button
            class="body w-[19.4rem] h-[3.25rem] mb-[0.938rem] bg-white rounded-2xl text-black border-0 cursor-pointer focus:outline-none focus:shadow-none"
          >
            <LoginSpinner
              v-if="loading"
              style="
                position: relative;
                left: 0%;
                top: 0%;
                border-top: 2px solid black;
              "
            />
            <span
              v-else
              class="text-center font-medium text-[1.063rem] text-background"
              >Confirm</span
            >
          </button>
          <!-- <button type="submit" class="body confirm-password__button">
                      Reset password
                    </button> -->
        </form>
        <div
          class="flex items-center justify-center text-white hover:bg-background"
        >
          <img src="../assets/arrow-left.svg" class="block" />
          <a
            href="/auth/login"
            class="text-white pl-[0.188rem] hover:underline bg-background text-[0.875rem] text-sm font-medium"
            @click="backToLogin()"
          >
            Back to log in
          </a>
        </div>
        <!-- <a href="#" class="confirm-password__back-link"> Back to log in</a> -->
      </div>
    </div>
    <!-- <MessageToaster
      v-if="showToaster"
      :message="toasterMessage"
      isSuccess="error"
      @update:showToaster="showToaster = $event"
    /> -->
  </div>
</template>

<!-- <script setup lang="ts">
import IconKey from "./icons/IconKey.vue";
</script> -->

<script setup lang="ts">
import { ref, onMounted, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import IconEyeSlash from "./icons/IconEyeSlash.vue";
import IconEye from "./icons/IconEye.vue";
import AuthService from "@/Services/AuthService";
import IconKey from "./icons/IconKey.vue";
import IconLock from "./icons/IconLock.vue";
import IconCheck from "./icons/IconCheck.vue";
import IconEyeSvg from "./icons/IconEyeSvg.vue";
import IconCheckCircle from "./icons/IconCheckCircle.vue";
import MessageToaster from "./MessageToaster.vue";
import IconCircleVue from "./icons/IconCircle.vue";
import LoginSpinner from "./common/Spinner.vue";
import { UserService } from "@/Services/UserService";
import LoadingSpinner from "./spinners/LoadingSpinner.vue";
// Route and router setup
const route = useRoute();
const router = useRouter();

// Reactive references
const emailkey = ref<string>((route.query.Emailkey as string) || "");
const newPassword = ref<string>("");
const confirmPassword = ref<string>("");
const newpasswordEmpty = ref<boolean>(false);
const confirmpasswordEmpty = ref<boolean>(false);
const showPassword = ref<boolean>(true);
const showConfirmPassword = ref<boolean>(true);
const notMatchPassword = ref<boolean>(false);
const svgFillColor1 = ref<string>("var(--textGraph)");
const svgFillColor2 = ref<string>("var(--textGraph)");
const svgStrokeColor = ref<string>("white");
const isPasswordLongEnough = ref<boolean>(false);
const isValidPassword = ref<boolean>(false);
const showToaster = ref<boolean>(false);
const toasterMessage = ref<string>("");
const loading = ref<boolean>(false);
const responseMessage = ref<string>("");
const passwordresponseMessage = ref<string>("");
const confirmpasswordresponseMessage = ref<string>("");
const isBlurred = ref<boolean>(false);

// Function to reset the password
const resetPassword = async () => {
  // loading.value = true;
  // if (!!newPassword.value.trim() !== !!confirmPassword.value.trim()) {
  //   showToaster.value = true;
  //   // showPassword.value = true;
  //   toasterMessage.value = "Passwords do not match!";
  //   loading.value = false;
  //   return;
  // }
  let hasError = false;

  if (newPassword.value.trim() === "") {
    newpasswordEmpty.value = true;
    passwordresponseMessage.value = "Enter the password.";
    hasError = true;
  } else {
    newpasswordEmpty.value = false;
  }

  if (confirmPassword.value.trim() === "") {
    confirmpasswordEmpty.value = true;
    confirmpasswordresponseMessage.value = "Enter the confirm password.";
    hasError = true;
  } else {
    confirmpasswordEmpty.value = false;
  }
  if (hasError) {
    return;
  }
  if (newPassword.value !== confirmPassword.value) {
    notMatchPassword.value = true;
    responseMessage.value = "Passwords do not match.";
    return;
  }
  if (
    !isPasswordValid(newPassword.value) &&
    !isPasswordValid(confirmPassword.value)
  ) {
    notMatchPassword.value = true;
    responseMessage.value = "Password does not meet the requirements.";
    loading.value = false;
    return;
  }

  try {
    const userService = new UserService();
    const authService = new AuthService();
    authService.ClearCookie("freddyUserId");
    authService.ClearCookie("refreshToken");
    const keyDetails = await userService.GetDetailsByEmailKey(
      emailkey.value,
      false
    );
    if (keyDetails) {
      router.push("/timeout");
    }
    const response = await authService.ResetPassword(
      newPassword.value,
      confirmPassword.value,
      emailkey.value
    );
    loading.value = false;
    if (response == "Password changed successfully.") {
      router.push("/resetpassword/status/success");
    }
  } catch (error: any) {
    responseMessage.value = "";
    loading.value = false;

    if (
      error.response.data.message ===
      "Password must contain at least one special character."
    ) {
      notMatchPassword.value = true;
      responseMessage.value =
        "Password must contain at least one special character.";
    } else {
      notMatchPassword.value = true;
      responseMessage.value = "Something went wrong.";
    }
    // showToaster.value = true;
    // toasterMessage.value = "Error resetting password. Please try again.";
  } finally {
    loading.value = false;
  }
};

// Password validation function
const isPasswordValid = (password: string): boolean => {
  const minLength = 8;
  const specialCharRegex = /[!@#$%^&*(),.?":{} |<>`\[\]~_+=\/;\\\'\-]/;
  return password.trim().length >= minLength && specialCharRegex.test(password);
};

// Function to check password and update UI accordingly
const checkNewPassword = () => {
  isValidPassword.value = true;
  if (newPassword.value.trim().length >= 8) {
    svgFillColor1.value = "#A7D0F8";
    isPasswordLongEnough.value = true;
  } else {
    svgFillColor1.value = "var(--textGraph)";
    isValidPassword.value = false;
    isPasswordLongEnough.value = false;
  }

  if (/[!@#$%^&*(),.?":{}|<>]/.test(newPassword.value)) {
    svgFillColor2.value = "#A7D0F8";
  } else {
    svgFillColor2.value = "var(--textGraph)";
    isValidPassword.value = false;
  }
};

const checkConfirmPassword = () => {
  isValidPassword.value = true;
  if (confirmPassword.value.trim().length >= 8) {
    svgFillColor1.value = "#A7D0F8";
    isPasswordLongEnough.value = true;
  } else {
    svgFillColor1.value = "var(--textGraph)";
    isValidPassword.value = false;
    isPasswordLongEnough.value = false;
  }

  if (/[!@#$%^&*(),.?":{}|<>]/.test(confirmPassword.value)) {
    svgFillColor2.value = "#A7D0F8";
  } else {
    svgFillColor2.value = "var(--textGraph)";
    isValidPassword.value = false;
  }
};

// Toggle password visibility
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

// Toggle confirm password visibility
const toggleShowConfirmPassword = () => {
  showConfirmPassword.value = !showConfirmPassword.value;
};

const clearerrors = () => {
  clearNewPasswordError();
  clearConfirmPasswordError();
};
const clearNewPasswordError = () => {
  notMatchPassword.value = false;
  isPasswordLongEnough.value = false;
  isValidPassword.value = false;
  newpasswordEmpty.value = false;
  confirmpasswordEmpty.value = true;
};

const clearConfirmPasswordError = () => {
  notMatchPassword.value = false;
  isPasswordLongEnough.value = false;
  isValidPassword.value = false;
  confirmpasswordEmpty.value = false;
};

const backToLogin = () => {
  router.push("/auth/login");
};

onMounted(() => {});

onBeforeMount(async () => {
  isBlurred.value = true;
  const userService = new UserService();
  const key = route.query.Emailkey || "";
  if (key == "") {
    isBlurred.value = false;
    return;
  }
  try {
    const details = await userService.GetDetailsByEmailKey(
      key as string,
      false
    );
    if (details == true) {
    isBlurred.value = false;
    router.push("/timeout");
  }
  } catch {
    isBlurred.value = false;
  }
  
  isBlurred.value = false;
});
</script>

<!-- <script lang="ts">
import IconEyeSlash from "./icons/IconEyeSlash.vue";
import IconEye from "./icons/IconEye.vue";
import AuthService from "@/Services/AuthService";
import IconKey from "./icons/IconKey.vue";
import IconLock from "./icons/IconLock.vue";
import IconCheck from "./icons/IconCheck.vue";
import IconEyeSvg from "./icons/IconEyeSvg.vue";
import IconCheckCircle from "./icons/IconCheckCircle.vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import MessageToaster from "./MessageToaster.vue";

export default {
  components: {
    IconEye,
    IconEyeSlash,
    IconKey,
    IconLock,
    IconCheck,
    IconEyeSvg,
    IconCheckCircle,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    //const isSuccess = ref<string>("");
    // Reactive references
    const emailkey = ref<string>((route.query.Emailkey as string) || "");
    const newPassword = ref<string>("");
    const confirmPassword = ref<string>("");
    const showPassword = ref<boolean>(false);
    const showConfirmPassword = ref<boolean>(false);
    const svgFillColor1 = ref<string>("var(--textGraph)");
    const svgFillColor2 = ref<string>("var(--textGraph)");
    const svgStrokeColor = ref<string>("white");
    const isPasswordLongEnough = ref<boolean>(false);
    const isValidPassword = ref<boolean>(false);
    let showToaster = ref<boolean>(true);
    let toasterMessage = ref<string>("");

    const resetPassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        alert("Passwords do not match!");
        return;
      }
      if (!isPasswordValid(newPassword.value)) {
        alert("Password does not meet the requirements!");
        return;
      }

      try {
        const authService = new AuthService();
        const response = await authService.ResetPassword(
          newPassword.value,
          confirmPassword.value,
          emailkey.value
        );
        if (response == "Password changed successfully.") {
          router.push("/resetpassword/status/success");
        }
      } catch (error) {
        // alert("Error resetting password. Please try again.");
        // router.push("/resetpassword/status/failed");
        showToaster.value = true;
        toasterMessage.value = "Error resetting password. Please try again.";
      }
    };

    const isPasswordValid = (password: string): boolean => {
      const minLength = 8;
      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
      return password.length >= minLength && specialCharRegex.test(password);
    };

    const checkPassword = () => {
      isValidPassword.value = true;
      if (newPassword.value.length >= 8) {
        svgFillColor1.value = "#A7D0F8";
        isPasswordLongEnough.value = true;
      } else {
        svgFillColor1.value = "var(--textGraph)";
        isValidPassword.value = false;
        isPasswordLongEnough.value = false;
      }

      if (/[!@#$%^&*(),.?":{}|<>]/.test(newPassword.value)) {
        svgFillColor2.value = "#A7D0F8";
      } else {
        svgFillColor2.value = "var(--textGraph)";
        isValidPassword.value = false;
      }
    };
    // Toggle password visibility
    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    // Toggle confirm password visibility
    const toggleShowConfirmPassword = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    const backToLogin = () => {
      router.push("/auth/login");
    };



    return {
      newPassword,
      confirmPassword,
      showPassword,
      showConfirmPassword,
      svgFillColor1,
      svgFillColor2,
      svgStrokeColor,
      isPasswordLongEnough,
      isValidPassword,
      resetPassword,
      checkPassword,
      toggleShowPassword,
      toggleShowConfirmPassword,
      backToLogin,
      toasterMessage,
      showToaster,
    };
  },
};
</script> -->

<!-- <style scoped lang="scss">
$background-primary: var(--Background-primary, #031525);
$login-lines: var(--LoginLines, #a7d0f8);
$selected: var(--Selected, #11385b);
$button-background: var(--ButtonBackground, #ffffff);
$white: #fff;
$black: #000;

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin container-size($width, $height) {
  width: $width;
  height: $height;
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin full-width-height {
  width: 100vw;
  height: 100vh;
}

.d-block{
    display: block;
}
.confirm-password {
  background-image: url(/src/assets/Background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  @include full-width-height;
  @include flex-center;

  &__container {
    background: $background-primary;
    border: 3px solid $login-lines;
    @include container-size(355px, auto);
    padding: 25px;
    @include border-radius(40px);
  }

  &__icon-container {
    @include container-size(64px, 64px);
    padding: 20px;
    border: 1px solid $login-lines;
    @include border-radius(16px);
    @include flex-center;
    margin: auto;
  }

  &__icon {
    @include flex-center;
  }

  &__title {
    color: $white;
    text-align: center;
    margin-top: 30px;
  }

  &__description {
    color: $white;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__back_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;

    &:hover {
      background-color: $background-primary;
    }
  }

  &__input-container {
    margin-bottom: 15px;
  }

  &__email-input {
    @include container-size(300px, 48px);
    padding: 12px 12px 12px 16px;
    @include border-radius(8px);
    border: 1px solid $selected;
    background: $selected;
    color: $white;
    font-size: 14px;

    &:focus,
    &:focus-visible {
      outline: 0 !important;
      box-shadow: none !important;
    }
    &::placeholder{
      color: #FFFFFF66;
    }
  }

  &__button {
    @include container-size(300px, 52px);
    @include border-radius(16px);
    margin-bottom: 15px;
    background: $button-background;
    color: $black;
    border: none;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }

  &__back-link {
    color: #ffffff;
  }

  &__instructions {
    color: $white;
    margin-bottom: 20px;

    p {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .check-text {
        margin-left: 10px;
      }
    }
  }

  .inner-input-container {
    position: relative;

    .input-eye {
      position: absolute;
      top: 15px;
      right: 6px;
    }
  }

  .forgot-password__back-link__back-login {
    color: white;
    background-color: $background-primary;
    padding-left: 3px;
  }

  .forgot-password__back-link__back-login:hover {
    text-decoration: underline;
  }
}
</style> -->
