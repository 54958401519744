<template>
  <div
    class="w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50 opacity-100"
  >
    <div class="text-center p-6 rounded-lg shadow-lg max-w-lg">
      <p class="text-[1.25rem] font-bold text-[#FFFFFF]">Oops!</p>
      <p class="text-[1.25rem] font-bold text-[#FFFFFF]">
        There was an error switching organizations.
      </p>
      <p class="mt-[0.625rem] text-[#CBD6E3] text-[1.063rem] font-medium">
        Please try again or contact support if the issue continues.
      </p>
      <IconLoadingError class="mt-10" />
      <div class="flex justify-center items-center mr-[1.125rem] mt-10">
        <button
          class="text-[#7BA8EF] border border-solid border-[#7BA8EF] w-20 rounded-lg py-[0.375rem] px-3"
          @click="reloadCurrentPage"
        >
          Reload
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconLoadingError from "../icons/IconLoadingError.vue";

const reloadCurrentPage = () => {
  window.location.reload();
};
</script>
