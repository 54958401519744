<template>
  <form
    class="flex flex-col border border-menuSelected p-4 rounded-[10px] gap-5 w-full py-5"
    @submit.prevent="handleSubmit"
  >
    <h2 class="text-[17px] font-medium">Update your personal information</h2>
    <div
      class="flex items-center justify-start mb-2"
    >
      <label for="current-password" class="text-white text-[14px] font-medium md:min-w-[345px]"
        >Current password</label
      >
      <div class="flex flex-col w-full md:w-[70%] gap-1.5">
        <div class="relative">
          <input
            :type="isCurrentPasswordVisible ? 'text' : 'password'"
            id="current-password"
            v-model="currentPassword"
            class="w-full  py-[6px] px-3.5 bg-backgroundSidebar border border-menuSelected rounded-lg"
            @focus="clearValidation"
          />
          <span
            class="absolute w-4 h-4 transform -translate-y-1/2 right-3 top-1/2"
          >
            <IconEye
              v-if="
                !isCurrentPasswordVisible &&
                !currentPasswordError &&
                !currentEmptyError
              "
              :class="{ 'opacity-50 cursor-default': !currentPassword }"
              @click="togglePasswordVisibility('current')"
            />
            <IconEyeSlash
              v-else-if="
                isCurrentPasswordVisible &&
                !currentPasswordError &&
                !currentEmptyError
              "
              :class="{ 'opacity-50 cursor-default': !currentPassword }"
              @click="togglePasswordVisibility('current')"
            />
            <IconAlertCircle v-else />
          </span>
        </div>
        <p class="flex justify-start text-xs font-normal text-[#D92D20]">
          {{ currentPasswordError }}
        </p>
      </div>
    </div>
    <div
      class="flex items-center justify-start mb-2"
    >
      <label for="new-password" class="text-white text-[14px] font-medium md:min-w-[345px]"
        >New password</label
      >
      <div class="flex flex-col w-full md:w-[70%] gap-1.5">
        <div class="relative">
          <input
            :type="isNewPasswordVisible ? 'text' : 'password'"
            id="new-password"
            v-model="newPassword"
            @focus="clearValidation"
            class="w-full py-[6px] px-3.5 bg-backgroundSidebar border border-menuSelected rounded-lg"
          />
          <span
            class="absolute w-4 h-4 transform -translate-y-1/2 right-3 top-1/2"
          >
            <IconEye
              v-if="!isNewPasswordVisible && !newEmptyError && !passwordCriteriaFailError"
              :class="{ 'opacity-50 cursor-default': !newPassword }"
              @click="togglePasswordVisibility('new')"
            />
            <IconEyeSlash
              v-else-if="isNewPasswordVisible && !newEmptyError && !passwordCriteriaFailError"
              :class="{ 'opacity-50 cursor-default': !newPassword }"
              @click="togglePasswordVisibility('new')"
            />
            <IconAlertCircle v-else-if="newEmptyError || passwordCriteriaFailError" />
          </span>
        </div>
        <p class="text-xs text-textGrey">
          Your new password must be more than 8 characters and have a special
          symbol.
        </p>
      </div>
    </div>
    <div class="flex items-center justify-start between mb-2">
      <label for="confirm-password" class="text-white text-[14px] font-medium md:min-w-[345px]"
        >Confirm new password</label
      >
      <div class="flex flex-col w-full md:w-[70%] gap-1.5">
        <div class="relative">
          <input
            :type="isConfirmPasswordVisible ? 'text' : 'password'"
            id="confirm-password"
            v-model="confirmPassword"
            @focus="clearValidation"
            class="w-full  py-[6px] px-3.5 bg-backgroundSidebar border border-menuSelected rounded-lg"
          />
          <span
            class="absolute w-4 h-4 transform -translate-y-1/2 right-3 top-1/2"
          >
            <IconEye
              v-if="
                !isConfirmPasswordVisible &&
                !mismatchPasswordError &&
                (!confirmEmptyError ||
                  (confirmPassword && (currentEmptyError || newEmptyError))) &&
                !passwordCriteriaFailError &&
                !backendFailError
              "
              @click="togglePasswordVisibility('confirm')"
              :class="{ 'opacity-50 cursor-default': !confirmPassword }"
            />
            <IconEyeSlash
              v-else-if="
                isConfirmPasswordVisible &&
                !mismatchPasswordError &&
                (!confirmEmptyError ||
                  (confirmPassword && (currentEmptyError || newEmptyError))) &&
                !passwordCriteriaFailError &&
                !backendFailError
              "
              @click="togglePasswordVisibility('confirm')"
              :class="{ 'opacity-50 cursor-default': !confirmPassword }"
            />
            <IconAlertCircle
              v-else-if="confirmEmptyError ||  mismatchPasswordError && (!currentEmptyError && !newEmptyError)"
            />
            <template v-else>
              <IconEye
                v-if="!isConfirmPasswordVisible"
                @click="togglePasswordVisibility('confirm')"
                :class="{ 'opacity-50 cursor-default': !confirmPassword }"
              />
              <IconEyeSlash
                v-else
                @click="togglePasswordVisibility('confirm')"
                :class="{ 'opacity-50 cursor-default': !confirmPassword }"
              />
            </template>
          </span>
        </div>
        <p
          class="flex justify-start text-xs font-normal text-[#D92D20]"
          v-if="
            mismatchPasswordError &&
            !currentEmptyError &&
            !newEmptyError &&
            !confirmEmptyError
          "
        >
          {{ mismatchPasswordError }}
        </p>
        <!-- <p
          class="flex justify-start text-xs font-normal text-[#D92D20]"
          v-if="!currentPassword"
        >
          {{ currentEmptyError }}
        </p>
        <p
          class="flex justify-start text-xs font-normal text-[#D92D20]"
          v-if="!newPassword"
        >
          {{ newEmptyError }}
        </p>
        <p
          class="flex justify-start text-xs font-normal text-[#D92D20]"
          v-if="!confirmPassword"
        >
          {{ confirmEmptyError }}
        </p> -->
        <p
          class="flex justify-start text-xs font-normal text-[#D92D20]"
          v-if="allEmptyError"
        >
          {{ allEmptyError }}
        </p>
        <p
          class="flex justify-start text-xs font-normal text-[#D92D20]"
          v-if="
            passwordCriteriaFailError &&
            !currentEmptyError &&
            !newEmptyError &&
            !confirmEmptyError
          "
        >
          {{ passwordCriteriaFailError }}
        </p>
        <p
          class="flex justify-start text-xs font-normal text-[#D92D20]"
          v-if="
            backendFailError &&
            !currentEmptyError &&
            !newEmptyError &&
            !confirmEmptyError
          "
        >
          {{ backendFailError }}
        </p>
      </div>
    </div>
    <div class="flex justify-end">
      <p id="error-message" class="text-xs font-normal text-red-500"></p>
    </div>
    <div class="flex justify-start gap-3">
      <button
        type="button"
        class="rounded-lg border border-buttonBlue py-[6px] px-[10px] flex items-center justify-center relative overflow-hidden text-[#7BA8EF]"
        @click="clearForm"
        :disabled="!isFormChanged"
        :class="{
          'text-buttonBlue cursor-default opacity-50': !isFormChanged,
          'cursor-pointer': isFormChanged,
        }"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="rounded-lg border border-buttonBlue py-[6px] px-[10px] flex items-center justify-center shadow-xs overflow-hidden bg-buttonBlue text-[#031525]"
        :disabled="!isFormChanged"
        :class="{
          'text-background cursor-default opacity-50': !isFormChanged,
          'cursor-pointer': isFormChanged,
        }"
      >
        Update password
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, defineEmits, computed } from "vue";
import IconEye from "@/components/icons/IconEye.vue";
import IconCircle from "@/components/icons/IconCircle.vue";
import IconEyeSlash from "@/components/icons/IconEyeSlash.vue";
import { UserService } from "../../../Services/UserService";
import IconAlertCircle from "@/components/icons/IconAlertCircle.vue";

const userService = new UserService();

const currentPassword = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const currentPasswordError = ref("");
const currentEmptyError = ref("");
const newEmptyError = ref("");
const confirmEmptyError = ref("");
const mismatchPasswordError = ref("");
const passwordCriteriaFailError = ref("");
const backendFailError = ref("");
const allEmptyError = ref("");

const isFormChanged = computed(() => {
  return (
    currentPassword.value !== "" ||
    newPassword.value !== "" ||
    confirmPassword.value !== ""
  );
});

const togglePasswordVisibility = (type: "current" | "new" | "confirm") => {
  if (type === "current" && currentPassword.value) {
    isCurrentPasswordVisible.value = !isCurrentPasswordVisible.value;
  } else if (type === "new" && newPassword.value) {
    isNewPasswordVisible.value = !isNewPasswordVisible.value;
  } else if (type === "confirm" && confirmPassword.value) {
    isConfirmPasswordVisible.value = !isConfirmPasswordVisible.value;
  }
};

const isCurrentPasswordVisible = ref<boolean>(false);
const isNewPasswordVisible = ref<boolean>(false);
const isConfirmPasswordVisible = ref<boolean>(false);
const emit = defineEmits(["password-updated"]); // Define the event

const handleSubmit = async () => {
  backendFailError.value = "";
  clearErrors();
  // Check if any of the password fields are empty
  if (!currentPassword.value)
    (currentEmptyError.value = "All fields are required."),
      (allEmptyError.value = "All fields are required.");
  if (!newPassword.value)
    (newEmptyError.value = "All fields are required."),
      (allEmptyError.value = "All fields are required.");
  if (!confirmPassword.value)
    (confirmEmptyError.value = "All fields are required."),
      (allEmptyError.value = "All fields are required.");
  if (currentPassword.value && newPassword.value && confirmPassword.value)
    allEmptyError.value = "";

  // Validate that the new password and confirm password are the same
  if (newPassword.value !== confirmPassword.value) {
    mismatchPasswordError.value =
      "There appears to be a mismatch between your new passwords. Please double-check and try again.";
    return;
  }

  // Additional password strength validation (optional)
  // if (!validatePasswordStrength(newPassword.value)) {
  //   passwordCriteriaFailError.value =
  //     "Password must be at least 8 characters long and contain at least one special character.";
  //   return;
  // }

  try {
    if( currentPassword.value !== "" &&
    newPassword.value !== "" &&
    confirmPassword.value !== ""){
     if (newPassword.value.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(newPassword.value)) {
      passwordCriteriaFailError.value =
        "Password must be at least 8 characters long and contain at least one special character.";
      return;
    }
      let isSuccess = await userService.UpdatePassword(
      currentPassword.value,
      newPassword.value,
      confirmPassword.value
    );
    if (!isSuccess) throw new Error("Password update failed.");
    clearForm();
    emit("password-updated");
    }
  } catch (e: any) {
    if (
      e.response.data ===
      "It seems you have entered the wrong password,please retry"
    ) {
      currentPasswordError.value =
        "It seems you have entered the wrong password, please retry.";
    } 
    else {
      backendFailError.value =
        "Can't update password at this moment. Please try again.";
    }
  }
};

// const displayError = (message: string, elementId: string = 'error-message') => {
//     const errorElement = document.getElementById(elementId);
//     if (errorElement) {
//         errorElement.innerText = message;

//         setTimeout(() => {
//             clearErrors(elementId);
//         }, 2000);
//     } else {
//         console.error('Error element not found');
//     }
// };

const clearValidation = () => {
  currentPasswordError.value = "";
  confirmEmptyError.value = "";
  newEmptyError.value = "";
  currentEmptyError.value = "";
  mismatchPasswordError.value = "";
  passwordCriteriaFailError.value = "";
  backendFailError.value = "";
  allEmptyError.value = "";
};

const clearErrors = (elementId: string = "error-message") => {
  const errorElement = document.getElementById(elementId);
  if (errorElement) {
    errorElement.innerText = "";
  } else {
    console.warn(`Error element with ID ${elementId} not found`);
  }
};

// const validatePasswordStrength = (
//   password: string
// ): { isValid: boolean; message: string } => {
//   if (password.length < 8) {
//     return {
//       isValid: false,
//       message: "Password must be at least 8 characters long.",
//     };
//   }

//   if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
//     return {
//       isValid: false,
//       message: "Password must contain at least one special character.",
//     };
//   }

//   return { isValid: true, message: "" };
// };

const clearForm = () => {
  currentPassword.value = "";
  newPassword.value = "";
  confirmPassword.value = "";
  currentPasswordError.value = "";
  confirmEmptyError.value = "";
  newEmptyError.value = "";
  currentEmptyError.value = "";
  mismatchPasswordError.value = "";
  passwordCriteriaFailError.value = "";
  backendFailError.value = "";
  allEmptyError.value = "";
};

// const showCurrentPassword = () => {
//     isCurrentPasswordVisible.value = !isCurrentPasswordVisible.value;
// };

// const showNewPassword = () => {
//     isNewPasswordVisible.value = !isNewPasswordVisible.value;
// };

// const showConfirmPassword = () => {
//     isConfirmPasswordVisible.value = !isConfirmPasswordVisible.value;
// };
</script>

<style scoped>
/* .password-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
 */
/* .form-group {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
    } */

/* .form-label {
        color: #fff;
    } */

/* .input-wrapper-block {
        display: flex;
        flex-direction: column;
        width: 70%;
        gap: 6px;
    } */

/* .input-wrapper {
        position: relative;
    } */

/* .form-input {
        width: 100%;
        padding: 10px 14px;
        color: #cbd6e3;
        background-color: #11385b;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 8px;
    } */

/* .input-icon {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
    } */

/* .input-hint {
        color: rgba(255, 255, 255, 0.2);
    } */

/* .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 20px;
    } */

/* .btn {
        padding: 10px 14px;
        border-radius: 8px;
        cursor: pointer;
    }

    .btn-secondary {
        color: #071a2b;
        background-color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.09);
    }

    .btn-primary {
        color: #fff;
        background-color: #7ba8ef;
        border: 1px solid rgba(255, 255, 255, 0.4);
    } */

/* .section-divider {
        background-color: rgba(255, 255, 255, 0.40);
        border: none;
        height: 1px;
        margin: 24px 0;
    }
 */
/* .error-message {
    color: red;
    margin-top: 10px;
    } */

/* @media (max-width: 991px) {
        .form-group {
            flex-direction: row;
        }
    } */
</style>
