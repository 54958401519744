<template>
  <transition name="modal">
    <div
      v-if="isVisible"
      class="fixed z-[9998] top-0 left-0 w-full h-full bg-modalBackgroundBlur flex justify-center items-center"
    >
      <div class="flex justify-center items-center">
        <div
          :class="{
            'p-5 bg-background rounded-[2.5rem] overflow-hidden text-white':
              largeModel,
            '2xl:w-[56.25rem] xl:w-[56.25rem] lg:w-[56.25rem] md:w-[45.625rem] sm:w-[31.25rem] xs:w-auto':
              largeModel,
          }"
        >
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <!-- <button class="modal-default-button" @click="$emit('close')">
                  OK
                </button> -->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalBox",
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    largeModel: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
