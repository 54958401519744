<template>
  <div
    class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center"
  >
    <div>
      <div
        class="bg-[var(--color-background)] border-[3px] border-[#a7d0f8] w-[350px] p-8 rounded-[40px]"
      >
        <div>
          <div>
            <div class="flex flex-col gap-6 items-center">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 28C3.5 14.469 14.469 3.5 28 3.5C41.531 3.5 52.5 14.469 52.5 28C52.5 41.531 41.531 52.5 28 52.5C14.469 52.5 3.5 41.531 3.5 28Z"
                  fill="#F79009"
                />
                <path
                  d="M3.5 28C3.5 14.469 14.469 3.5 28 3.5C41.531 3.5 52.5 14.469 52.5 28C52.5 41.531 41.531 52.5 28 52.5C14.469 52.5 3.5 41.531 3.5 28Z"
                  stroke="#DC6803"
                  stroke-width="7"
                />
                <path
                  d="M27.9987 23.3359V28.0026M27.9987 32.6693H28.0104M39.6654 28.0026C39.6654 34.4459 34.442 39.6693 27.9987 39.6693C21.5554 39.6693 16.332 34.4459 16.332 28.0026C16.332 21.5593 21.5554 16.3359 27.9987 16.3359C34.442 16.3359 39.6654 21.5593 39.6654 28.0026Z"
                  stroke="white"
                  stroke-width="2.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div class="text-center text-2xl font-bold">Access denied</div>
              <div
                class="text-center text-white font-medium text-[0.938rem] tracking-[-0.009rem]"
              >
                It seems like there is no organisation you belong to.
                <br />
                <br />
                Go back and try again.

                <!-- <br />
                Please contact your organization admin. -->
              </div>
              <div
                class="bg-[#FFF] p-4 flex flex-row -mt-3 items-center justify-center w-[19rem] h-[3.25rem] shadow-md rounded-2xl cursor-pointer"
                @click="backToLogin()"
              >
                <button class="text-background font-medium">Go back</button>
              </div>
              <!-- <div
                class="flex items-center justify-center text-white hover:bg-background -mt-3"
              >
                <img src="../assets/arrow-left.svg" class="block" />
                <a
                  href="/auth/login"
                  class="text-white pl-[0.188rem] hover:underline bg-background text-[0.875rem] text-sm font-medium"
                  @click="backToLogin()"
                >
                  Back to log in
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";

const backToLogin = () => {
  router.push("/user/register");
};
</script>

<style scoped>
.outerContainer {
  width: 100vw;
  height: 100vh;
  display: grid;
  align-content: center;
  justify-content: center;
}

.image-19,
.image-19 * {
  box-sizing: border-box;
}

.image-19 {
  position: absolute;
  background-color: #001b33;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
}

.login-frame,
.login-frame * {
  box-sizing: border-box;
}

.login-frame {
  background: var(--background-primary, #031525);
  border-radius: 38px;
  border-style: dashed;
  border-color: var(--loginlines, #a7d0f8);
  border-width: 3px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 350px;
}

.frame-2278 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-2183 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.icon-check {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  position: relative;
  overflow: visible;
}

.textarea {
  color: var(--white, #ffffff);
  text-align: center;
  position: relative;
  width: 304px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.it-seems-like-you-don-t-have-access-to-any-of-the-selected-organisations-please-contact-your-organisation-admin-or-select-a-different-one {
  color: #ffffff;
  text-align: center;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background: var(--white, #ffffff);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 304px;
  height: 52px;
  position: relative;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
}

.label {
  color: var(--background-primary, #031525);
  text-align: center;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
}

.label:hover {
  cursor: pointer;
}
</style>
