<template>
  <svg
    width="440"
    height="232"
    viewBox="0 0 440 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62.8103 127.401C63.0201 125.834 63.3041 122.175 61.9358 119.463C61.0311 117.678 59.6255 116.644 57.7465 116.393C55.6709 116.115 53.9229 116.751 52.5559 118.277C50.343 120.75 50.0745 124.621 50.0581 124.79L52.629 124.823C52.629 124.823 52.8289 122.258 54.1351 120.813C54.9053 119.964 55.9636 119.622 57.29 119.8C58.4506 119.956 59.2562 120.521 59.7605 121.516C60.8925 123.751 60.2034 127.496 60.1984 127.534L62.6907 128.153C62.6966 128.11 62.7526 127.831 62.8111 127.394L62.8103 127.401Z"
      fill="#A7D0F8"
    />
    <path
      d="M10.5 153.023C16 153.023 43.5 152.523 43.5 152.523L43 154.523H5C5 154.523 5 153.023 10.5 153.023Z"
      fill="#A15C07"
    />
    <path d="M240 132.5H201V154.5H240V132.5Z" fill="#11385B" />
    <path
      d="M85.4268 228.103C85.409 229.984 83.8787 231.5 81.997 231.5C80.1153 231.5 78.5849 229.984 78.5673 228.102L78.0374 171.5C78.0168 169.297 79.7969 167.5 81.9998 167.5C84.2029 167.5 85.983 169.297 85.9622 171.5L85.4268 228.103Z"
      fill="white"
    />
    <path
      d="M340.427 228.103C340.409 229.984 338.879 231.5 336.997 231.5C335.115 231.5 333.585 229.984 333.567 228.102L333.037 171.5C333.017 169.297 334.797 167.5 337 167.5C339.203 167.5 340.983 169.297 340.962 171.5L340.427 228.103Z"
      fill="white"
    />
    <path
      d="M124.254 113.922H316.014V120.602C316.014 127.602 310.324 133.292 303.324 133.292H136.954C129.954 133.292 124.264 127.602 124.264 120.602V113.922H124.254Z"
      fill="#E0F2FE"
    />
    <path
      d="M133.124 2.17188H307.124C312.024 2.17188 316.004 6.15186 316.004 11.0519V114.232H124.254V11.0519C124.254 6.15186 128.234 2.17188 133.134 2.17188H133.124Z"
      fill="#E0F2FE"
    />
    <path
      d="M305.423 10.2266H136.043C133.834 10.2266 132.043 12.0174 132.043 14.2266V103.307C132.043 105.516 133.834 107.307 136.043 107.307H305.423C307.632 107.307 309.423 105.516 309.423 103.307V14.2266C309.423 12.0174 307.632 10.2266 305.423 10.2266Z"
      fill="#11385B"
    />
    <path
      d="M207.023 58.5234C207.023 50.7915 213.291 44.5234 221.023 44.5234C228.755 44.5234 235.023 50.7915 235.023 58.5234C235.023 66.2554 228.755 72.5234 221.023 72.5234C213.291 72.5234 207.023 66.2554 207.023 58.5234Z"
      fill="#FDB022"
    />
    <path
      d="M207.023 58.5234C207.023 50.7915 213.291 44.5234 221.023 44.5234C228.755 44.5234 235.023 50.7915 235.023 58.5234C235.023 66.2554 228.755 72.5234 221.023 72.5234C213.291 72.5234 207.023 66.2554 207.023 58.5234Z"
      stroke="#F79009"
      stroke-width="4"
    />
    <g clip-path="url(#clip0_29616_978)">
      <path
        d="M221.022 55.8594V58.526M221.022 61.1927H221.029M227.689 58.526C227.689 62.2079 224.704 65.1927 221.022 65.1927C217.34 65.1927 214.355 62.2079 214.355 58.526C214.355 54.8441 217.34 51.8594 221.022 51.8594C224.704 51.8594 227.689 54.8441 227.689 58.526Z"
        stroke="white"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <path
      d="M0 158.5C0 156.291 1.79086 154.5 4 154.5H394C396.209 154.5 398 156.291 398 158.5C398 160.709 396.209 162.5 394 162.5H3.99999C1.79086 162.5 0 160.709 0 158.5Z"
      fill="white"
    />
    <path
      d="M0 158.5C0 156.291 1.79086 154.5 4 154.5H394C396.209 154.5 398 156.291 398 158.5C398 160.709 396.209 162.5 394 162.5H3.99999C1.79086 162.5 0 160.709 0 158.5Z"
      fill="white"
      fill-opacity="0.4"
    />
    <path
      d="M47.3816 122.066L43 154.773L74.1549 155.684L77.6448 129.632L47.3816 122.066Z"
      fill="#A7D0F8"
    />
    <defs>
      <clipPath id="clip0_29616_978">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(213.023 50.5234)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
