<!-- <template>
  <div class="verification-container">
    <h1>Verification link expired.</h1>
  </div>
</template>

<script setup></script>

<style scoped>
.verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 109px;
  font-size: 32px;
}
</style> -->
<template>
  <div class="error-page-container" style="background-color: white">
    <span class="error-page-icon-container">
      <svg
        width="143"
        height="104"
        viewBox="0 0 143 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M97 27V46.126C98.5141 47.2152 99.5 48.9925 99.5 51C99.5 54.3137 96.8137 57 93.5 57C93.2162 57 92.9371 56.9803 92.6638 56.9422L76.7208 70.0295C75.2267 71.256 73.0213 71.039 71.7948 69.5449C70.5684 68.0509 70.7853 65.8454 72.2794 64.619L87.5915 52.0495C87.5314 51.7087 87.5 51.358 87.5 51C87.5 48.9925 88.4859 47.2152 90 46.126V27C90 25.067 91.567 23.5 93.5 23.5C95.433 23.5 97 25.067 97 27Z"
          fill="#7287B3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.5 27.5L51.5 27.5V34.5L3.5 34.5C1.567 34.5 -3.8147e-06 32.933 -3.8147e-06 31C-3.8147e-06 29.067 1.567 27.5 3.5 27.5Z"
          fill="#7287B3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5 45.5L46.5 45.5V52.5L12.5 52.5C10.567 52.5 9 50.933 9 49C9 47.067 10.567 45.5 12.5 45.5Z"
          fill="#7287B3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.5 63.5H48.5V70.5H25.5C23.567 70.5 22 68.933 22 67C22 65.067 23.567 63.5 25.5 63.5Z"
          fill="#7287B3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M90.5 94C113.696 94 132.5 75.196 132.5 52C132.5 28.804 113.696 10 90.5 10C67.304 10 48.5 28.804 48.5 52C48.5 75.196 67.304 94 90.5 94ZM90.5 100C117.01 100 138.5 78.5097 138.5 52C138.5 25.4903 117.01 4 90.5 4C63.9903 4 42.5 25.4903 42.5 52C42.5 78.5097 63.9903 100 90.5 100Z"
          fill="#7287B3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M90.5 90C111.487 90 128.5 72.9868 128.5 52C128.5 31.0132 111.487 14 90.5 14C69.5132 14 52.5 31.0132 52.5 52C52.5 72.9868 69.5132 90 90.5 90ZM142.5 52C142.5 80.7188 119.219 104 90.5 104C61.7812 104 38.5 80.7188 38.5 52C38.5 23.2812 61.7812 0 90.5 0C119.219 0 142.5 23.2812 142.5 52ZM132.5 52C132.5 75.196 113.696 94 90.5 94C67.304 94 48.5 75.196 48.5 52C48.5 28.804 67.304 10 90.5 10C113.696 10 132.5 28.804 132.5 52ZM138.5 52C138.5 78.5097 117.01 100 90.5 100C63.9903 100 42.5 78.5097 42.5 52C42.5 25.4903 63.9903 4 90.5 4C117.01 4 138.5 25.4903 138.5 52Z"
          fill="white"
        />
      </svg>
    </span>
    <span class="error-page-title">
      The link you are trying to access has expired.
    </span>
  </div>
</template>
<style>
.error-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.error-page-title,
.error-page-description,
.error-page-contact {
  font-family: "Lato", sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #021d2d;
}

.error-page-title {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
}

.error-page-description {
  margin-top: 20px;
}

.error-page-contact {
  color: #4a62d3;
  display: inline-block;
  cursor: pointer;
}
</style>
