import './assets/main.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import { store } from './store/store';
import './index.css';
import { createPinia } from 'pinia';
//import { VOnClickOutside } from '@vueuse/components';


const app = createApp(App);
const pinia = createPinia();
app.use(router);
app.use(pinia);
//app.use(vClickOutside);
app.use(store);
app.mount('#app');