<template>
  <div
    v-if="isDataReady"
    class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center"
  >
    <div
      class="bg-[var(--color-background)] w-[21.875rem] p-[1.563rem] rounded-[2.5rem] border-[0.188rem] border-lightBlue relative flex flex-col items-center"
    >
      <div class="flex justify-center items-center mb-5">
        <!-- <div class="border p-5 rounded-2xl border-[#A7D0F8] flex gap-2">
          <img class="w-6 h-6" :src="logoUrl" />
          <span>PITS</span>
        </div> -->
        <div
          class="border p-5 rounded-2xl border-[#A7D0F8] flex gap-2 items-center"
        >
          <img class="w-[1.875rem] h-[1.875rem]" :src="logoUrl" />
          <span>{{ companyName }}</span>
        </div>
        <img
          src="../assets/party-alcohol.svg"
          class="absolute top-[70px] left-12"
        />
        <img
          src="../assets/party-hat.svg"
          class="absolute top-[23px] right-[57px]"
        />
        <img
          src="../assets/party-popper-left.svg"
          class="absolute top-6 left-6"
        />
        <img
          src="../assets/party-popper.svg"
          class="absolute top-[70px] right-[23px]"
        />
      </div>
      <div>
        <h1 class="h5Bold text-center text-2xl font-bold">
          Company welcomes you
        </h1>
        <p class="bodySmall text-sm font-medium text-center mt-2">
          Thank you for joining please proceed to the interface and enjoy
          Freddy.
        </p>
      </div>
      <button
        type="button"
        class="flex w-[18.75rem] h-[3.25rem] p-4 justify-center items-center rounded-2xl bg-white mt-4"
        @click="proceedToFreddy"
      >
        <p class="h7 text-center text-[#031525] text-lg font-medium cursor-pointer">
          Continue
        </p>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UserService } from "@/Services/UserService";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const companyName = ref<string>("");
let userId = ref<number>(0);
const isLogoLoaded = ref(false);
const isDataReady = ref(false);

const logoUrl = ref("");
const proceedToFreddy = () => {
  router.push("/auth/login");
};

const handleLogoLoad = () => {
  isLogoLoaded.value = true;
};

// const UserIdString = ref<string>(sessionStorage.getItem("userId") || "");
// onMounted(async () => {
//   userId.value = parseInt(UserIdString.value);
//   const userService = new UserService();
//   const companyById = await userService.GetUserCompanyNameById(userId.value);
//   companyName.value = companyById;
// });
const UserIdString = ref<string>(sessionStorage.getItem("userId") || "");
onMounted(async () => {
  userId.value = parseInt(UserIdString.value);
  const userService = new UserService();
  const email = sessionStorage.getItem("userEmail") || "";
  const invitedOrg = sessionStorage.getItem("InvitedOrg") || "";

  let domain = "";
  if (email != "" && email.includes("@")) {
    domain = "@" + email.split("@")[1];
  }
  const companyDetail = await userService.GetUserCompanyNameAndLogo(
    domain,
    invitedOrg
  );
  companyName.value = companyDetail.name;
  logoUrl.value = companyDetail.logo;
  isDataReady.value = !!companyName.value && !!logoUrl.value;
  sessionStorage.removeItem("InvitedOrg");
});
</script>
