<template>
  <div class="flex text-red-500 mb-2.5 items-center">
    <span class="mr-2.5 w-4 h-4 flex-shrink-0">
      <IconCircleVue />
    </span>
    <p class="error-message text-[12px] text-[#E71D41] font-medium mt-0.5">
      {{ message }}
    </p>
  </div>
</template>

<script>
import IconCircleVue from "../icons/IconCircle.vue";

export default {
  name: "ErrorMessage",
  components: {
    IconCircleVue,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
