<template>
  <div class="bg-backgroundSidebar inline-block w-full border border-menuSelected focus-within:border-white rounded-lg min-w-[200px] p-2">
    <button @click="toggleDropdown" class="border-none cursor-pointer flex items-center justify-between w-full text-white font-normal text-base">
      <div style="display: flex; align-items: center">
        <img v-if="selectedItem" :src="timezonicon" style="width: 30px; height: 30px;" alt="Avatar" class="w-[40px] h-[40px] rounded-full mr-[10px]" />
        <span v-if="selectedItem">{{ selectedItem.timeZoneName }}</span>
      </div>

      <div>
        <!-- <i  :class="dropdownOpen ? 'rotate-45 transform rotate-[137]' : 'rotate-45 transform rotate-45'"
        class="inline-block w-2.5 h-2.5 border-t-2 border-r-2 border-black ml-1"></i> -->
        <i
    :class="dropdownOpen ? '-rotate-45' : 'rotate-[135deg]'"
    class="inline-block w-2.5 h-2.5 border-t-2 border-r-2 text-[#ffff] border-white transform ml-1"
  ></i>
      </div>
    </button>

    <div v-if="dropdownOpen" ref="dropdown" class="absolute top-full left-0 bg-[#11385b] border border-[rgba(255,255,255,0.4)] shadow-lg z-50 w-full rounded-[10px] h-[228px] overflow-y-scroll">
      <div v-for="(item, index) in dataList" :key="index" @click="selectItem(item)" class="px-4 py-2 cursor-pointer flex items-center hover:bg-background">
        <img style="width: 25px; height: 25px;" :src="timezonicon" class="w-[40px] h-[40px] rounded-full mr-[10px]" />
        <span>{{ item.timeZoneName }}</span>
      </div>
    </div>
  </div>



</template>

<script lang="ts">
import type { ITimezone } from "@/Models/Common/ITimezone";
import { ref, type PropType } from "vue";
import timezonicon from "@/assets/timezone.svg";
export default {
  data() {
    return {
      dropdownOpen: false,
      timezonicon
    };
  },
  props: {
    dataList: {
      type: Array as PropType<ITimezone[]>,
      default: () => [],
    },

    selectedItem: {
      type: Object as PropType<ITimezone | null>,
      default: null,
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectItem(item: ITimezone) {
      this.dropdownOpen = false;
      this.$emit("selectedTimezoneId", item.id);
    },

    handleClickOutside(event: { target: any; }) {

      const dropdown = this.$refs.dropdown;
      if (dropdown && !(dropdown as HTMLElement).contains(event.target) && this.dropdownOpen) {
        this.dropdownOpen = false;
      }
    },
    getFlagUrl() {
      return `<svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="white"
                      stroke-opacity="0.4"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>`; // Example flag URL pattern
    }
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
  },
};
</script>

<style scoped>
/* .dropdown {
  background: var(--selected, #11385b);
  display: inline-block;
  width: 100%;
  border: 1px solid;
  border-color: var(--text-graph, rgba(255, 255, 255, 0.4));
  border-radius: 10px;
  min-width: 200px;
  padding: 10px;
}
 */
/* .dropdown-button {
  background-color: #c0c0c000;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: white;
} */

/* .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
 */
/* .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--selected, #11385b);
  border-color: var(--text-graph, rgba(255, 255, 255, 0.4));
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 100%;
  border-radius: 10px;
  height: 228px;
  overflow-y: scroll;
} */

/* .dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: hsla(160, 100%, 37%, 0.2);
} */

/* .arrow-down::after,
.arrow-up::after {
  content: "";
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
}

.arrow-down::after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-up::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
} */
</style>