import { defineStore } from "pinia";

export const useUserStore = defineStore("userStore", {
    state: (): { isNotRegisteredInvited: boolean, userId: number | null } => ({
        isNotRegisteredInvited: false,
        userId: null
    }),
    actions: {
        setNotRegisteredInvited(value: boolean): void {
            this.isNotRegisteredInvited = value;
        },
        setUserId(userId: number | null): void {
            this.userId = userId;
        },
    },
});
