<template>
  <div
    class="bg-custom-bg bg-no-repeat bg-cover w-full h-screen flex items-center justify-center"
  >
    <div
      class="bg-[#031525] border-[3px] border-[#a7d0f8] w-[350px] h-auto p-6 rounded-[40px]"
    >
      <div
        class="w-16 h-16 p-5 border border-[#a7d0f8] rounded-[20px] flex items-center justify-center m-auto"
      >
        <IconKey class="flex items-center justify-center" />
      </div>
      <h1
        class="text-[29px] font-[600] leading-9 text-white text-center mt-[20px]"
      >
        Check your email
      </h1>
      <p
        class="text-white mt-[5px] mb-[22px] text-center text-base font-normal leading-6"
      >
        We sent a verification code to {{ email }}
      </p>
      <form>
        <div class="mb-4">
          <button
            @click.prevent="EnterCodeManually"
            class="text-[17px] font-medium leading-5 w-[300px] h-[52px] p-3 pl-4 pr-3 rounded-2xl border border-white bg-white text-[#031525] text-center placeholder:text-black hover:cursor-pointer focus:outline-0 focus:shadow-none"
          >
            Enter code manually
          </button>
        </div>
      </form>
      <p
        class="text-sm font-medium leading-4 mt-5 mb-[22px] text-center text-[#CBD6E3]"
      >
        Didn't receive the email?
        <a
          :class="{ disabled: resendEmailCompleted }"
          @click="resendEmail"
          class="text-sm font-medium leading-4 text-left text-[#a7d0f8] cursor-pointer hover:bg-transparent"
        >
          Click to resend
        </a>
      </p>
      <div class="flex justify-center items-center gap-[2px] text-white">
        <div class="mail-code__arrow mr-2">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8327 6.9974H1.16602M1.16602 6.9974L6.99935 12.8307M1.16602 6.9974L6.99935 1.16406"
              stroke="white"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <a
          :href="`${loginUrl}`"
          @click.prevent="backToLogin"
          class="text-sm font-medium leading-4 text-left"
        >
          Back to log in
        </a>
      </div>
    </div>
    <MessageToaster
      v-show="successToaster"
      :message="toasterMessage"
      isSuccess="success"
      :title="title"
      @update:successToaster="successToaster = $event"
      class="-mb-[2rem]"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import IconKey from "./icons/IconKey.vue";
import AuthService from "@/Services/AuthService";
import MessageToaster from "./MessageToaster.vue";
import { UserService } from "@/Services/UserService";

export default defineComponent({
  components: {
    IconKey,
    MessageToaster,
  },
  data() {
    return {
      loginUrl: `${window.location.origin}/auth/login`,
      router: useRouter(),
      route: useRoute(),
      email: "",
      title: "",
      toasterMessage: "",
      successToaster: false,
      resendEmailCompleted: false,
    };
  },
  methods: {
    EnterCodeManually() {
      this.router.push({
        path: "/token/verify",
        query: { emailKey: this.route.query.emailKey },
      });
    },
    async resendEmail() {
      this.resendEmailCompleted = true;
      this.email = sessionStorage.getItem("userEmail");
      const authService = new AuthService();
      const emailDetails = await authService.GetEmailDetails(this.email);
      this.fullName = emailDetails.fullName;
      this.userId = emailDetails.userId;
      const isLoginstring = sessionStorage.getItem("isLogin") || "false";
      const isLogin = isLoginstring === "true";

      // this.fullName = sessionStorage.getItem("fullName");
      //this.userId = sessionStorage.getItem("userId");
      try {
        const data = {
          email: this.email,
          fullName: this.fullName,
          userId: this.userId,
          isLogin: isLogin,
        };
        const response = await authService.ResendVerificationEmail(data);
        this.resendEmailCompleted = false;
        this.successToaster = true;
        this.errorMessage = "";
        this.title = "Verification resent";
        this.toasterMessage =
          "We've resent the verification email, please check your inbox.";
        this.router.push({
          path: "/user/enter-code",
          query: { emailKey: response.emailKey },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.resendEmailCompleted = false;
        setTimeout(() => {
          this.successToaster = false;
          this.showToaster = false;
        }, 2000);
      }
    },
    backToLogin() {
      this.router.push("/auth/login");
    },
  },
  created() {
    this.email = sessionStorage.getItem("userEmail");
  },
  async mounted() {
    // const userService = new UserService();
    // const key = this.route.query.emailKey || "";
    // if (key == "") {
    //   return;
    // }
    // const keyDetails = await userService.GetDetailsByEmailKey(key);
    // if (keyDetails) {
    //   this.router.push("/timeout");
    // }
  },
});
</script>
<style scoped>
.disabled {
  pointer-events: none;
  cursor: none;
}
</style>
