<template>
  <aside class="side-menu grow">
    <div id="top-section">
      <header class="dashboard-header flex justify-between">
        <a :href="freddyHubUrl">
          <h1 class="h5Bold dashboard-title text-2xl font-bold">Freddy Hub</h1>
        </a>
      </header>
      <header class="">
        <section class="text-sm text-gray-300 mb-3">
          Manage your organization here.
        </section>
      </header>
      <hr class="seperator border-y-1 border-slate-600 mt-5 mb-5" />
      <header class="dashboard-header flex justify-between">
        <h1 class="h5Bold dashboard-title text-2xl font-bold">Assistants</h1>
        <img
          src="../../../assets/chevron-left.svg"
          alt="Assistant icon"
          class="organization-icon cursor-pointer"
          @click="closeView"
        />
      </header>
      <section class="text-sm text-gray-300 mb-3">
        Please select an Assistant.
      </section>
      <header class="max-h-60 overflow-y-auto">
        <div
          class="user-profile mb-2"
          :class="{
            'selected-item bg-menuSelected rounded-xl':
              AppConfigGlobal.CurrentAssistantId === assistant.id,
          }"
          v-if="listOfAssistant.length"
          v-for="(assistant, index) in listOfAssistant"
          @click="userAssistantSelected(assistant.id)"
        >
          <div
            class="user-info flex p-3 items-center rounded-xl hover:bg-menuSelected cursor-pointer"
          >
            <img
              loading="lazy"
              src="../../../assets/freddy_new_logo.svg"
              alt="User avatar"
              class="avatar size-8 mr-2"
            />
            <span class="username flex-auto">{{ assistant.name }}</span>
            <img
              loading="lazy"
              src="@/assets/new-chat-icon.svg"
              alt="Menu icon"
              class="menu-icon flex-none w-8"
              @click="createNewChat"
            />
          </div>
        </div>
        <section class="bodySmall supporting-text2" v-else>
          No assistants found.
        </section>
      </header>
      <hr
        v-if="listOfAssistant.length"
        class="seperator border-y-1 border-slate-600 mt-5"
      />
      <SearchInput @searchThread="onThreadSearch" />
    </div>
    <nav
      v-if="isThreadListLoading && !isNewAddedThreadLoading"
      class="chat-history loading"
    >
      <IconLoading :isLoading="isThreadListLoading" />
    </nav>
    <nav
      class="flex flex-col gap-2 chat-history mt-2 mb-2 overflow-y-auto pr-1"
      id="threadHeight"
      v-else
      :style="{ height: `calc(100vh - ${getAssistantTopHeight + 68 + 20}px)` }"
    >
      <div
        class="empty-thread-text"
        v-if="
          !listOfSearchedUserThread.Today?.length &&
          !listOfSearchedUserThread.ThisWeek?.length &&
          !listOfSearchedUserThread.ThisMonth?.length &&
          !listOfSearchedUserThread.Older?.length
        "
      >
        No threads found.
      </div>
      <ChatSection
        v-if="listOfSearchedUserThread?.Today?.length"
        title="Today"
        :chats="listOfSearchedUserThread.Today"
        @updateThreadList="updateUserThreadList"
        @updateThreadRename="updateThreadRename"
      />
      <hr
        v-if="listOfSearchedUserThread?.Today?.length"
        class="divider border-[#FFFFFF17]"
      />
      <ChatSection
        v-if="listOfSearchedUserThread?.ThisWeek?.length"
        title="This Week"
        :chats="listOfSearchedUserThread.ThisWeek"
        @updateThreadList="updateUserThreadList"
        @updateThreadRename="updateThreadRename"
      />
      <hr
        v-if="listOfSearchedUserThread?.ThisWeek?.length"
        class="divider border-[#FFFFFF17]"
      />
      <ChatSection
        v-if="listOfSearchedUserThread?.ThisMonth?.length"
        title="ThisMonth"
        :chats="listOfSearchedUserThread.ThisMonth"
        @updateThreadList="updateUserThreadList"
        @updateThreadRename="updateThreadRename"
      />
      <hr
        v-if="listOfSearchedUserThread?.ThisMonth?.length"
        class="divider border-[#FFFFFF17]"
      />
      <ChatSection
        v-if="listOfSearchedUserThread?.Older?.length"
        title="Older"
        :chats="listOfSearchedUserThread.Older"
        @updateThreadList="updateUserThreadList"
        @updateThreadRename="updateThreadRename"
        class="mb-[5.125rem]"
      />
    </nav>
  </aside>
</template>

<script setup lang="ts">
import {
  ref,
  onMounted,
  defineEmits,
  watch,
  computed,
  nextTick,
  onBeforeUnmount,
} from "vue";
import SearchField from "./SearchField.vue";
import ChatSection from "./ChatSection.vue";
import { UserService } from "@/Services/UserService";
import type { IUserAssistant } from "../../../Models/Common/IAssistant";
import type { IUserThreadGroup } from "../../../Models/Common/IUserThread";
import AppConfigGlobal from "../../../core/config/uiSettings";
import IconLoading from "@/components/common/IconLoading.vue";
import SearchInput from "../../Dashboards/AssistantDashBoard/SearchField.vue";

    const emits = defineEmits([
        "onAssistantsDashBoardClosed",
        "emptyThreadList",
        "assistantSelected",
    ]);

    const userService = new UserService();

const listOfAssistant = ref<IUserAssistant[]>([]);
const listOfUserThread = ref<IUserThreadGroup>({} as IUserThreadGroup);
const listOfUserThreadId = ref<number[]>([]);
const searchThreadText = ref<string>("");
var freddyHubUrl = ref<string>("");
const getAssistantTopHeight = ref(0);
const listOfSearchedUserThread = computed(() => {
  if (!searchThreadText.value) return { ...listOfUserThread.value };
  return {
    Today: listOfUserThread.value.Today.filter((m) =>
      m.name.toLowerCase().includes(searchThreadText.value.toLowerCase())
    ),
    ThisWeek: listOfUserThread.value.ThisWeek.filter((m) =>
      m.name.toLowerCase().includes(searchThreadText.value.toLowerCase())
    ),
    ThisMonth: listOfUserThread.value.ThisMonth.filter((m) =>
      m.name.toLowerCase().includes(searchThreadText.value.toLowerCase())
    ),
    Older: listOfUserThread.value.Older.filter((m) =>
      m.name.toLowerCase().includes(searchThreadText.value.toLowerCase())
    ),
  };
});

const isThreadListLoading = ref<boolean>(false);
const isNewAddedThreadLoading = ref<boolean>(false);
const threadHeight = ref<HTMLElement | null>(null);
const topSection = ref<HTMLElement | null>(null);
let rtime: number;
let timeout = false;
const delta = 200;
const userAssistantSelected = (assistantId: number) => {
  threadHeight.value = document.getElementById("threadHeight");
  topSection.value = document.getElementById("top-section");

  if (threadHeight.value && topSection.value) {
    getAssistantTopHeight.value = topSection.value.scrollHeight;
    threadHeight.value.style.height = `calc(100vh - (${
      getAssistantTopHeight.value + 68
    }px) - 20px)`;
  }
  if (AppConfigGlobal.CurrentAssistantId == assistantId) return;
  AppConfigGlobal.CurrentAssistantId = assistantId;
  GetUserThreads();
  emits("assistantSelected");
};

    const reloadAssistantDashBoard = () => {
        GetUserThreads();
    };

    const updateThreadRename = async (threadId: number, newTitle: string) => {
        const threadResponse = await userService.GetUserThreadsByAssistantId();
        listOfUserThread.value = { ...threadResponse };
    };

    const updateUserThreadList = (threadId: number) => {
        listOfUserThread.value.Today = listOfUserThread.value?.Today?.filter(
            (t) => t.id !== threadId
        );
        listOfUserThread.value.ThisWeek = listOfUserThread.value?.ThisWeek?.filter(
            (t) => t.id !== threadId
        );
        listOfUserThread.value.ThisMonth = listOfUserThread.value?.ThisMonth?.filter(
            (t) => t.id !== threadId
        );
        listOfUserThread.value.Older = listOfUserThread.value?.Older?.filter(
            (t) => t.id !== threadId
        );
    };

    const GetUserThreads = async () => {
        isThreadListLoading.value = true;
        try {
            const threadResponse = await userService.GetUserThreadsByAssistantId();
            listOfUserThread.value = { ...threadResponse };

            AppConfigGlobal.CurrentThreadId =
                listOfUserThread.value?.Today[0]?.id ||
                listOfUserThread.value?.ThisWeek[0]?.id ||
                listOfUserThread.value?.ThisMonth[0]?.id ||
                listOfUserThread.value?.Older[0]?.id ||
                0;
            listOfUserThreadId.value = [
                ...listOfUserThread.value?.Today.map((o) => o.id),
                ...listOfUserThread.value?.ThisWeek.map((o) => o.id),
                ...listOfUserThread.value?.ThisMonth.map((o) => o.id),
                ...listOfUserThread.value?.Older.map((o) => o.id),
            ];
        } catch (e) {
            if (!listOfUserThreadId.value.length) {
                emits("emptyThreadList");
                listOfUserThread.value = {
                    Older: [],
                    ThisMonth: [],
                    ThisWeek: [],
                    Today: [],
                };
            }
            //---> ToDo:- Error message if needed.
        } finally {
            isThreadListLoading.value = false;
            isNewAddedThreadLoading.value = false;
        }
    };

    const createNewChat = () => {
        AppConfigGlobal.CurrentThreadId = 0;
    };
    const closeView = () => {
        AppConfigGlobal.CurrentThreadId = 0;
        emits("onAssistantsDashBoardClosed");
    };

const onThreadSearch = (searchText: string) => {
  searchThreadText.value = searchText;
};
const updateDashboard = () => {
  const newThread = listOfUserThread.value.Today?.[0];
};

const handleResize = () => {
  if (!timeout) {
    timeout = true;
    threadHeight.value = document.getElementById("threadHeight");
    topSection.value = document.getElementById("top-section");
    if (threadHeight.value && topSection.value) {
      getAssistantTopHeight.value = topSection.value.scrollHeight;
      threadHeight.value.style.height = `calc(100vh - (${
        getAssistantTopHeight.value + 68
      }px) - 20px)`;
    }
    setTimeout(resizeEnd, delta);
  }
};

const resizeEnd = () => {
  if (new Date().getTime() - rtime < delta) {
    setTimeout(resizeEnd, delta);
  } else {
    timeout = false;
    console.log("Done resizing");
  }
};

onMounted(async () => {
  window.addEventListener("resize", handleResize);
  listOfAssistant.value =
    AppConfigGlobal.Organizations.find(
      (o) => o.id == AppConfigGlobal.CurrentOrganizationId
    )?.assistants || [];
  AppConfigGlobal.CurrentAssistantId = listOfAssistant.value[0]?.id ?? 0;
  freddyHubUrl.value = `${AppConfigGlobal.FreddyHubUrl}/organizations/${AppConfigGlobal.CurrentOrganizationId}`;

  await GetUserThreads();
  emits("assistantSelected");
  nextTick(() => {
    threadHeight.value = document.getElementById("threadHeight");
    topSection.value = document.getElementById("top-section");

    if (threadHeight.value && topSection.value) {
      getAssistantTopHeight.value = topSection.value.scrollHeight;
      threadHeight.value.style.height = `calc(100vh - (${
        getAssistantTopHeight.value + 68
      }px) - 20px)`;
      console.log(getAssistantTopHeight.value);
    }
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

    watch(
        () => AppConfigGlobal.CurrentThreadId,
        (newValue) => {
            if (newValue === 0) return;

            if (!listOfUserThreadId.value.includes(newValue)) {
                isNewAddedThreadLoading.value = true;
                const newThread = {
                    id: newValue,
                    lastUsedOn: "2024-10-16T13:57:58.197",
                    name: "New Thread",
                };
                listOfUserThread.value?.Today?.unshift({ ...newThread });
                listOfUserThreadId.value.unshift(newValue);

                setTimeout(() => {
                    GetUserThreads();
                }, 10000);
            }
        }
    );
    watch(
        () => listOfUserThread.value,
        (newVal, oldVal) => {
            if (newVal !== oldVal) {
                // Trigger the dashboard update when the list changes
                updateDashboard();
            }
        },
        { deep: true }
    );
</script>

<!--<style scoped lang="scss">
@import "@/assets/componentmainstyle";

.side-menu {
  background-color: #071a2b;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 5px 5px 25px;
  width: 400px;
  gap: 16px;
}

.dashboard-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.dashboard-title {
  color: #fff;
  padding: 0px 12px;
}

.user-header {
  display: flex;
  flex-direction: column;
  color: #fff;
  padding-right: 20px;
}

.user-profile {
  align-items: center;
  /*background: linear-gradient(90deg, #11385b 0%, #071a2b 100%);*/
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  min-height: 50px;
  padding: 5px 13px;
}

.user-profile:hover {
  @extend .selected-item;
}

.user-profile:last-child {
  /*background-color: #031525;*/
}

.user-info {
  align-items: center;
  display: flex;
  gap: 20px;
  width: 100%;
}

.user-info:hover {
  cursor: pointer;
}

.avatar {
  border-radius: 10px;
  height: 40px;
  width: 40px;
}

.menu-icon {
  height: 32px;
  width: 32px;
}
.menu-icon:hover {
  cursor: pointer;
}

.chat-history {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20px;
  padding-right: 20px;
  overflow-y: auto;
  @extend .scroll-width;

  &.loading {
    align-items: center;
    justify-content: center;
  }

  hr:last-of-type {
    display: none;
  }
}

.divider {
  border: none;
  border-top: 1px solid var(&#45;&#45;separatorSemiTransparent);
  margin: 16px 0;
}
.seperator {
  border: none;
  border-top: 1px solid var(&#45;&#45;textGraph);
  margin: 0;
  margin-right: 20px;
}

.user-footer {
  margin-top: 232px;
}

.username:hover {
  cursor: pointer;
}

.organization-icon:hover {
  cursor: pointer;
}

.supporting-text2 {
  color: var(&#45;&#45;text-tetriary, #cbd6e3);
  text-align: left;
  position: relative;
  align-self: stretch;
  padding: 0px 12px 12px 12px;
}
.empty-thread-text {
  align-self: center;
  @extend .supporting-text2;
}
</style>-->
