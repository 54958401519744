<template>
  <div
    class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center"
  >
    <div
      class="bg-[var(--color-background)] border-[0.188rem] border-lightBlue w-[21.875rem] px-5 pt-5 pb-5 rounded-[40px]"
    >
      <div
        class="w-16 h-16 p-5 rounded-2xl m-auto flex justify-center items-center border border-lightBlue"
      >
        <div class="flex justify-center items-center">
          <svg
            class="key-01"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <path
              d="M34 17.9999C34 16.9762 33.6094 15.9526 32.8284 15.1716C32.0474 14.3905 31.0237 14 30 14M30 30C36.6274 30 42 24.6274 42 18C42 11.3726 36.6274 6 30 6C23.3726 6 18 11.3726 18 18C18 18.5474 18.0366 19.0862 18.1076 19.6141C18.2244 20.4824 18.2827 20.9165 18.2434 21.1912C18.2025 21.4773 18.1504 21.6315 18.0094 21.8838C17.874 22.126 17.6354 22.3646 17.1583 22.8417L6.93726 33.0627C6.59135 33.4086 6.4184 33.5816 6.29472 33.7834C6.18506 33.9624 6.10425 34.1575 6.05526 34.3615C6 34.5917 6 34.8363 6 35.3255V38.8C6 39.9201 6 40.4802 6.21799 40.908C6.40973 41.2843 6.71569 41.5903 7.09202 41.782C7.51984 42 8.07989 42 9.2 42H14V38H18V34H22L25.1583 30.8417C25.6354 30.3646 25.874 30.126 26.1162 29.9906C26.3685 29.8496 26.5227 29.7975 26.8088 29.7566C27.0835 29.7173 27.5176 29.7756 28.3859 29.8924C28.9138 29.9634 29.4526 30 30 30Z"
              stroke="#A7D0F8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <h1
        class="text-white text-center mt-5 text-[1.813rem] font-semibold leading-[36px]"
      >
        Forgot password?
      </h1>
      <p
        class="text-white text-sm mt-2 text-center text-[12px] font-normal leading-4"
      >
        No worries, we'll send you reset instructions.
      </p>
      <form @submit.prevent="resetPassword">
        <div class="mt-5">
          <input
            type="text"
            class="w-full h-12 px-4 py-3 pl-4 pr-9 mb-2 rounded-lg bg-[var(--color-menu-selected)] text-white font-semibold text-sm focus:outline outline-1 outline-white placeholder:text-[var(--color-text-grey)] placeholder:font-semibold placeholder:text-sm"
            placeholder="Enter your email"
            :class="{
              'border border-[#E71D41]': emailEmpty || invalidEmail,
              'border border-[var(--color-menu-selected)]': !(
                emailEmpty || invalidEmail
              ),
            }"
            v-model.trim="email"
            @input="clearEmailError"
          />
          <div
            v-if="emailEmpty || invalidEmail"
            class="flex text-[#E71D41] mb-2.5 items-center"
          >
            <span class="mr-2.5 w-4 h-4 flex-shrink-0">
              <IconCircleVue />
            </span>
            <p class="error-message text-[12px] font-medium">
              {{ responseMessage }}
            </p>
          </div>
        </div>
        <button
          class="w-[18.75rem] h-[50px] rounded-2xl mt-[15px] bg-white text-black cursor-pointer flex justify-center items-center text-lg p-4 focus:outline-0 focus:shadow-none focus-within:outline-0 focus-within:shadow-none"
        >
          <LoginSpinner
            v-if="loading"
            style="
              position: relative;
              left: 0%;
              top: 0%;
              border-top: 2px solid black;
            "
          />
          <span v-else class="font-medium text-[#031525]">Reset Password</span>
        </button>
      </form>
      <div
        class="text-white gap-1.5 flex justify-center items-center mt-[22px]"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8327 6.9974H1.16602M1.16602 6.9974L6.99935 12.8307M1.16602 6.9974L6.99935 1.16406"
            stroke="white"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <a
          href="/auth/login"
          class="text-center block text-white hover:underline text-sm font-medium"
          @click="goBackToLogin()"
        >
          Back to log in
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { provide, ref } from "vue";
import { useRouter } from "vue-router";
import IconKey from "./icons/IconKey.vue";
import AuthService from "@/Services/AuthService";
import { useEmailStore } from "./Store/emailstore";
import LoginSpinner from "./common/Spinner.vue";
import IconCircleVue from "./icons/IconCircle.vue";

const router = useRouter();
const { setEmail } = useEmailStore();
const emailEmpty = ref<boolean>(false);
const invalidEmail = ref<boolean>(false);
const email = ref<string>("");
const error = ref<string>("");
const loading = ref<boolean>(false);
const responseMessage = ref<string>("");
const resetPassword = async () => {
  try {
    if (email.value == "" || !validateEmailaddress(email.value)) {
      error.value = "";
      emailEmpty.value = true;
      responseMessage.value = "Enter a valid email.";
      return;
    } else {
      loading.value = true;
      const authService = new AuthService();
      const res1 = await authService.SendPasswordResetUrl(email.value);
      sessionStorage.setItem("resetPasswordEmail", email.value);
      if (res1 == "success") {
        setEmail(email.value);
        loading.value = false;
        // router.push({ path: "/checkemail", query: { email: email.value } });
        router.push({ path: "/checkemail" });
        // router.push("/checkemail");
      }
    }
  } catch (error: any) {
    loading.value = false;
    console.log(error);
    if ((error.response.data.message = "User not found.")) {
      invalidEmail.value = true;
      responseMessage.value = "Email not found.";
    }
  }
};
const onInvalidEmail = (event: any) => {
  const email = event.target.value;
  if (!validateEmailaddress(email)) {
    invalidEmail.value = true;
    responseMessage.value = "Enter a valid email.";
  } else {
    invalidEmail.value = false;
    responseMessage.value = "";
  }
};
const validateEmailaddress = (email: any) => {
  const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if (!emailPattern.test(email)) {
    return false;
  } else return true;
};
const clearEmailError = () => {
  emailEmpty.value = false;
  invalidEmail.value = false;
  responseMessage.value = "";
};
const goBackToLogin = () => {
  router.push("/auth/login");
};
</script>
