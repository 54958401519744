<template>

    <div
      
      class="w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50 opacity-100 transition-opacity duration-500"
    >
      <!-- initial loading while switching from on eorganization -->
  
      <div class="flex flex-col justify-center items-center w-[470px]" v-if="isOnline && errorSwitching">
        <p class="text-[1.25rem] font-bold mb-10 text-center">
          Unpacking your desk at {{ organizationName }}.... <br/> Hang on! 
        </p>
        <IconLoadingOrg />
        <div class="flex items-center gap-3 ml-[2.125rem]">
          <div class="w-[24.875rem] h-2 bg-[#ffffff66] border rounded-lg">
            <div
              class="h-full bg-white transition-all duration-[100ms] ease-linear"
              :style="{ width: progress + '%' }"
            ></div>
          </div>
          <p class="text-[0.875rem] font-medium">{{ progress.toFixed(0) }}%</p>
        </div>
        <div class="flex justify-center">
          <svg
            width="263"
            height="65"
            viewBox="0 0 263 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.42842 61.1064C7.41062 62.988 5.88028 64.5039 3.99863 64.5039C2.11685 64.5039 0.586459 62.9878 0.568845 61.1061L0.0390249 4.50354C0.0184054 2.30069 1.79844 0.503906 4.00139 0.503906C6.20449 0.503906 7.98459 2.30092 7.96376 4.50392L7.42842 61.1064Z"
              fill="white"
            />
            <path
              d="M262.428 61.1064C262.411 62.988 260.88 64.5039 258.999 64.5039C257.117 64.5039 255.586 62.9878 255.569 61.1061L255.039 4.50354C255.018 2.30069 256.798 0.503906 259.001 0.503906C261.204 0.503906 262.985 2.30092 262.964 4.50392L262.428 61.1064Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>

</template>

<script setup lang="ts">
import { ref, onMounted, defineProps } from "vue";
import IconLoadingOrg from "../icons/IconLoadingOrg.vue";
import useNetworkStatus  from "@/Services/NetworkStatusService";

const { isOnline } = useNetworkStatus();

// Define props
const props = defineProps<{
  settingsList?: { img: string; label: string }[];
  data?: { img: string; label: string }[];
  isDisabled?: boolean;
  errorSwitching?: boolean;
  organizationName: string;
}>();
const isSwitchSuccess = ref<boolean>(false);
const loading = ref(false);

const progress = ref(0);

const startProgress = () => {
  progress.value = 0;

  const interval = 100; // Update every 100ms
  const duration = 2000; // Total duration in ms
  const increment = (interval / duration) * 100;

  const timer = setInterval(() => {
    progress.value = Math.min(Math.round(progress.value + increment), 100);
    if (progress.value >= 100) {
      clearInterval(timer);
      isSwitchSuccess.value = true;
    }
  }, interval);
};

const handleSelection = () => {
  console.log("test");
};

// Lifecycle hook
onMounted(() => {
  startProgress();
});
</script>
