<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_28459_3227)">
        <path d="M7.99967 5.33594V8.0026M7.99967 10.6693H8.00634M14.6663 8.0026C14.6663 11.6845 11.6816 14.6693 7.99967 14.6693C4.31778 14.6693 1.33301 11.6845 1.33301 8.0026C1.33301 4.32071 4.31778 1.33594 7.99967 1.33594C11.6816 1.33594 14.6663 4.32071 14.6663 8.0026Z" stroke="#F04438" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_28459_3227">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>